<p-radioButton (onClick)="onCheck()"
               [(ngModel)]="value"
               [class.ng-dirty]="invalid"
               [class.ng-invalid]="invalid"
               [disabled]="isDisabled"
               [name]="groupName"
               [ngClass]="className"
               [value]="optionValue"></p-radioButton>
<div *ngIf="label"
     class="iw-label"
     for="item"
     title="{{label | translate}}">{{ label | translate }}
</div>
