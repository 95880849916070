<iw-modal-form (pressCancel)="onPressCancel()"
               (pressOk)="onPressOk()"
               [btnOk]="okDisabled"
               [height]="height"
               [title]="title"
               [width]="width">
    <ng-container *ngIf="!tempdataText">
        <div *ngFor="let e of struct"
             class="col-xs-10">
            <div *ngIf="e?.title">
                <iw-cct-element-dropdown [input]="getNode(e)"
                                         [labelAlign]="'top'"
                                         [label]="e.title | translate"
                                         class="iw-input col-xs-12"></iw-cct-element-dropdown>
            </div>
            <div *ngIf="e?.children"
                 style="padding-left:10px;">
                <ng-template #recursiveList
                             let-list>
                    <div *ngFor="let node of list"
                         class="col-xs-12">
                        <iw-cct-element-dropdown [input]="getNode(node)"
                                                 [labelAlign]="'top'"
                                                 [label]="node.title | translate"
                                                 class="iw-input col-xs-12"></iw-cct-element-dropdown>
                        <div *ngIf="node.children.length > 0"
                             style="padding-left:10px;">
                            <ng-container
                                    *ngTemplateOutlet="recursiveList; context:{ $implicit: node.children }"></ng-container>
                        </div>
                    </div>
                </ng-template>
                <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: e.children }"></ng-container>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="tempdataText">
        <iw-textarea [height]="430"
                     [isDisabled]="true"
                     [label]="'Tempdata'"
                     [value]="tempdataText"
                     class="iw-textarea col-xs-12"></iw-textarea>

        <iw-textarea [height]="120"
                     [isDisabled]="false"
                     [label]="'details_tempdata' | translate"
                     [value]="tempdataObj"
                     class="iw-textarea col-xs-12"></iw-textarea>

    </ng-container>

    <div class="row">
        <iw-textarea #salaryCalcInfo
                     *ngIf="results"
                     [height]="150"
                     [isDisabled]="true"
                     [value]="results"
                     class="iw-textarea col-xs-12"></iw-textarea>
    </div>
</iw-modal-form>