import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RestApiService} from '@app/sam-base/core/rest-api';
import {AgeData, AgeDataOutput} from '@app/sam-base/models/agence-data.model';
import {environment} from '@root/environments/environment';
import {TT6Ges} from '@sam-base/models';
import {Ppagence} from '@sam-base/models/placement';
import {ProfileService} from '@shared/profile/profile.service';
import {KeycloakService} from 'keycloak-angular';
import {from, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

export const LOCAL_STORAGE_AGENCY_KEY = '_#SAM_USER_AGENCY#_';

@Injectable()
export class AgencyHandlerService {

    protected readonly _baseUrl: string;

    constructor(private readonly _profileService: ProfileService, private readonly _keycloakService: KeycloakService,
                public readonly restService: RestApiService, public readonly _http: HttpClient) {
        this._baseUrl = environment.backendURL;
    }

    /** Returns agency for current logged user */
    public loadAgencyId(): Observable<string> {
        return from(this.getLocalStorageKey())
            .pipe(switchMap(key => {
                const value = localStorage.getItem(key);
                if (value) {
                    return of(value);
                }

                return this.loadDefaultAgency();
            }));
    }


    public loadDefaultAgency(): Observable<string> {
        return from(this.getLocalStorageKey())
            .pipe(switchMap((key, index) => {
                const value = localStorage.getItem(key);
                if (value) {
                    return of(value);
                }

                return this.getGestions()
                    .pipe(map(gestions => {
                        const nogestion = gestions[0].nogestion!;
                        localStorage.setItem(key, nogestion);
                        return nogestion;
                    }));
            }));
    }

    public getGestions(): Observable<TT6Ges[]> {
        return this._http.get<TT6Ges[]>(environment.backendURL + 'tenant/gestions');
    }

    /** Set agency for current user, reloads the page */
    public setAgencyId(agencyId: string) {
        from(this.getLocalStorageKey())
            .subscribe(key => {
                localStorage.setItem(key, agencyId);
                window.location.reload();
            });
    }


    public getDataText<T>(columnName: string, ageId: string): Observable<T> {
        return (this._http
            .get<T>(this._baseUrl + `datatexts/ppagence/${ageId}/${columnName}`));
    }

    public convertAgeData<T>(columnName: string, ageId: string, data: AgeData): Observable<AgeDataOutput> {
        return (this._http.post<AgeDataOutput>(this._baseUrl + `agedatatexts/${ageId}/${columnName}`, data));
    }

    public savePpagence(ppagence: Ppagence) {
        return this._http.post(this._baseUrl + 'ppagence/save', ppagence);
    }

    private async getLocalStorageKey(): Promise<string> {
        return LOCAL_STORAGE_AGENCY_KEY + this._keycloakService.getUsername() + '_' + (await this._profileService.getTenant());
    }
}

