import {IwRestEntity, IwRestEntityId} from '@app/sam-base/core/rest-api';

@IwRestEntity('sapar')
export class Sapar {
    @IwRestEntityId() public annee?: string;
    public lieuSoc?: string;
    public cantonSoc?: string;
    public avsTxEmp?: number;
    public avsTxSoc?: number;
    public avsTxAdm?: number;
    public avsTxaMd?: number;
    public avsMntEx?: number;
    public avsGsBas?: string;
    public avsGsSal?: string;
    public avsGsCot?: string;
    public avsGsCoe?: string;
    public avsGsAdm?: string;
    public avsGsNs?: string;
    public avsGsEx?: string;
    public avsGsBex?: string;
    public avsExAnn?: boolean;
    public avsAuto?: number;
    public avsNocli?: string;
    public acTxEmp?: number;
    public acTxSoc?: number;
    public ac2TxEmp?: number;
    public ac2TxSoc?: number;
    public acGsSal?: string;
    public ac2GsSal?: string;
    public acGsNs?: string;
    public ac2GsNs?: string;
    public acGsCot?: string;
    public ac2GsCot?: string;
    public acGsCoe?: string;
    public ac2GsCoe?: string;
    public acGsNb?: string;
    public ac2GsNb?: string;
    public acsalmax?: number;
    public ac2salmax?: number;
    public acAuto?: number;
    public avsacCnd?: string;
    public cnaGsBas?: string;
    public cnaGsSal?: string;
    public cnaGsCot?: string;
    public cnaGsCoe?: string;
    public cnaGsBpr?: string;
    public cnasalmax?: number;
    public cnaheumax?: number;
    public cnaAuto?: number;
    public cnaNocli?: string;
    public lacActive?: boolean;
    public lacAuto?: number;
    public pgmBasmod?: number;
    public pgmGsAba?: string;
    public pgmGsBas?: string;
    public pgmGsSal?: string;
    public pgmGsCot?: string;
    public pgmGsCoe?: string;
    public pgmGsBpr?: string;
    public pgmGsPpr?: string;
    public pgmsalmax?: number;
    public pgmAuto?: number;
    public pgmNocli?: string;
    public lppGsBas?: string;
    public lppGsSal?: string;
    public lppGsCot?: string;
    public lppGsCoe?: string;
    public lppGsCoo?: string;
    public lppGsNs?: string;
    public lppGsCft?: string;
    public lppGsCfe?: string;
    public lppCoord?: boolean;
    public lppCoomod?: number;
    public lppCoomnt?: number;
    public lppSalmin?: number;
    public lppSalmax?: number;
    public lppMinded?: number;
    public lppHor?: boolean;
    public lppHorMa?: number;
    public lppHorMi?: number;
    public lppHorCo?: number;
    public lppGshlpp?: boolean;
    public lppPrasai?: string;
    public lppHsmode?: number;
    public lppAuto?: number;
    public lppNbhan?: number;
    public impGsBas?: string;
    public impGsCot?: string;
    public impGsCof?: string;
    public impGsCor?: string;
    public impGsAis?: string;
    public imcGsCot?: string;
    public imcGsCof?: string;
    public imcGsCor?: string;
    public impGsNbj?: string;
    public impComtra?: string;
    public ishlkupmod?: number;
    public repGsCot?: string;
    public alfGsCoe?: string;
    public alfGsCot?: string;
    public alfAuto?: number;
    public alfGsind?: string;
    public alfGsInm?: string;
    public cu1Titre?: string;
    public cu1Shtit?: string;
    public cu1Active?: boolean;
    public cu1Basmod?: number;
    public cu1GsAba?: string;
    public cu1GsBas?: string;
    public cu1GsCot?: string;
    public cu1CsCoe?: string;
    public cu1GsCnd?: string;
    public cu1Auto?: number;
    public cu2Titre?: string;
    public cu2Shtit?: string;
    public cu2Active?: boolean;
    public cu2Basmod?: number;
    public cu2GsAba?: string;
    public cu2GsBas?: string;
    public cu2GsCot?: string;
    public cu2GsCoe?: string;
    public cu2GsCnd?: string;
    public cu2Auto?: number;
    public cu3Titre?: string;
    public cu3Shtit?: string;
    public cu3Active?: boolean;
    public cu3Basmod?: number;
    public cu3GsAba?: string;
    public cu3GsBas?: string;
    public cu3GsSal?: string;
    public cu3GsCot?: string;
    public cu3GsCoe?: string;
    public cu3GsNs?: string;
    public cu3Auto?: number;
    public cu3Salmax?: number;
    public cu3Salmin?: number;
    public cu3Subl?: boolean;
    public cu4Titre?: string;
    public cu4Shtit?: string;
    public cu4Active?: boolean;
    public cu4Basmod?: number;
    public cu4GsAba?: string;
    public cu4GsBas?: string;
    public cu4GsSal?: string;
    public cu4GsCot?: string;
    public cu4GsCoe?: string;
    public cu4GsNs?: string;
    public cu4Salmax?: number;
    public cu4Salmin?: number;
    public cu4Subl?: boolean;
    public cu4Auto?: number;
    public cu5Active?: boolean;
    public cu6Active?: boolean;
    public gsSalBru?: string;
    public gsSalNet?: string;
    public gsSalNap?: string;
    public gsTotDed?: string;
    public gsTotRet?: string;
    public gsTotCoe?: string;
    public gsTotPrv?: string;
    public gsSalNeg?: string;
    public gsNbH?: string;
    public gsNbJ?: string;
    public gsNbJavs?: string;
    public gsNbJis?: string;
    public hsOp?: string;
    public mj1Active?: boolean;
    public mj1Titre?: string;
    public mj1Shtit?: string;
    public mj1GsBas?: string;
    public mj1GsInd?: string;
    public mj1Prv?: string;
    public mj1Auto?: number;
    public mj2Active?: boolean;
    public mj2Titre?: string;
    public mj2Shtit?: string;
    public mj2GsBas?: string;
    public mj2GsInd?: string;
    public mj2GsPrv?: string;
    public mj2Auto?: number;
    public mj3Active?: boolean;
    public mj3Titre?: string;
    public mj3Shtit?: string;
    public mj3GsBas?: string;
    public mj3GsInd?: string;
    public mj3GsPrv?: string;
    public mj3Auto?: number;
    public mj4Active?: boolean;
    public mj4Titre?: string;
    public mj4Shtit?: string;
    public mj4GsBas?: string;
    public mj4GsInd?: string;
    public mj4GsPrv?: string;
    public mj4Auto?: number;
    public langueBs?: number;
    public bsfrx?: string;
    public bsFrxEml?: string;
    public csfrx?: string;
    public cs06frx?: string;
    public chqfrx?: string;
    public compteNap?: string;
    public anNap?: boolean;
    public cptagrpemp?: boolean;
    public cptagrpgs?: boolean;
    public cptagrpmod?: number;
    public cptaan?: boolean;
    public cptaanspc?: boolean;
    public cptaanobli?: boolean;
    public cptatxfcoe?: boolean;
    public cptatxfpay?: boolean;
    public cptatxfchq?: boolean;
    public jParAn?: number;
    public hParJ?: number;
    public lpayifnotcalc?: boolean;
    public mpaId1?: string;
    public mpaId2?: string;
    public mpaId3?: string;
    public mpaId4?: string;
    public mpaId5?: string;
    public mpaId8?: string;
    public mpaId9?: string;
    public allownb?: boolean;
    public lastnochq?: number;
    public libcatemp1?: string;
    public libcatemp2?: string;
    public libcatemp3?: string;
    public cat1Force?: boolean;
    public cat2Force?: boolean;
    public cat3Force?: boolean;
    public libuserdat?: string;
    public libuserda2?: string;
    public libuserda3?: string;
    public libusercha?: string;
    public libuserch2?: string;
    public libuserch3?: string;
    public usertext1?: string;
    public usertext2?: string;
    public usertext3?: string;
    public usertext4?: string;
    public usertext5?: string;
    public saData?: string;
    public saXmldata?: string;
    public userId?: string;
    public userCreat?: string;
    public userModif?: string;
    public active?: boolean;
    public smtpConfigId?: number;
    public emailSalary?: string;
    public emailInvoice?: string;
    public fidname?: string;
    public fidname2?: string;
    public fidadr1?: string;
    public fidadr2?: string;
    public fidnpa?: string;
    public fidlieu?: string;
    public fidpays?: string;
    public fidofsnr?: string;
    public entname?: string;
    public entdep?: string;
    public entadr1?: string;
    public entadr2?: string;
    public entadr3?: string;
    public entreg?: string;
    public entcp?: string;
    public entnpa?: string;
    public entlieu?: string;
    public entpays?: string;
    public entofsnr?: string;
    public sdaccord?: string;
    public sdentc?: string;
    public laaEmail?: string;
    public pgmEmail?: string;
    public entityMultiWindow?: boolean;
}
