export enum OperationEvents {
    GECLI_FACNC = 'gecli_facnc',
    GECLI_RELEVE = 'gecli_releve',
    GECLI_DEBSIT = 'gecli_debsit',
    GECLI_EMAIL = 'gecli_email',
    GECLI_ENCAISSEMENT = 'gecli_encaissement',
    FICHE_SAL_CALCULATE = 'fiche_sal_calculate',
    FICHE_SAL_PUBLISH = 'fiche_sal_publish',
    DELETE_DEDUCTION = 'delete_deduction',

    CREATE_NOTE_CREDIT = 'create_note_credit',
    DOWNLOAD_FACTURE = 'download_facture',
    FACTURE_EMAIL = 'facture_email',
    FACTURE_RAPPEL = 'facture_rappel',
    FACTURE_DELETE = 'facture_delete',
    SEND_OPEN_INVOICES = 'send_open_invoices',


    FICHE_SAL_DOWNLOAD = 'FICHE_SAL_DOWNLOAD',
    FICHE_SAL_MAIL = 'FICHE_SAL_MAIL',
    FICHE_SAL_DELETE = 'FICHE_SAL_DELETE',

    SAEMP_EMAIL = 'saemp_email',
    SAEMP_BS_CURYEAR = 'saemp_bs_curyear',
    SAEMP_LAA_BS = 'saemp_laa_bs',
    SAEMP_PGM_BS = 'saemp_pgm_bs',

    AGI_EMAIL = 'agi_email',
    ACA_EMAIL = 'aca_email'
}
