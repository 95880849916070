import {IwColumn, IwRestEntity, IwRestEntityId} from '@sam-base/core';

export enum PrintRequestType {
    INVOICE = 'INVOICE', SALARY = 'SALARY', INVOICE_REMINDER = 'INVOICE_REMINDER'
}

@IwRestEntity('print-request')
export class PrintRequest {
    @IwColumn({
        index: 0,
    }) @IwRestEntityId()
    public id?: number;
    public type?: PrintRequestType;
    public entityId?: string;
    public approved?: boolean;
    public downloaded?: boolean;
    public downloadedAt?: string;
    public docPath?: string;
    public docId?: string;
    public docName?: string;
}