<div class="iw-radiofield">
    <div [ngClass]="{'center': centered}"
         class="radio-container">
        <iw-radiofield (check)="onCheck($event)"
                       *ngFor="let option of radioOptions"
                       [invalid]="isInvalid"
                       [isDisabled]="isGroupDisabled"
                       [label]="option.label | translate"
                       [optionValue]="option.value"
                       [value]="value"
                       name="radios"></iw-radiofield>
    </div>
</div>
