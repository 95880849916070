<span class="table-cell">
  <ng-container [ngSwitch]="column?.type">
    <span *ngSwitchCase="'boolean'"
          class="table-cell-boolean">
      <span *ngIf="value"
            class="fas fa-check"></span>
      <span *ngIf="!value"
            class="fas fa-ban"></span>
      <ng-container *ngIf="!value">{{ '' }}</ng-container>
    </span>
    <span *ngSwitchCase="'warning'"
          class="table-cell-boolean">
      <span *ngIf="value"
            class="fas fa-triangle-exclamation"></span>
      <ng-container *ngIf="!value">{{ '' }}</ng-container>
    </span>
    <iw-cell-active-period *ngSwitchCase="'activePer'"
                           [row]="row"
                           [value]="value"
                           class="table-cell-activePer">
    </iw-cell-active-period>

    <iw-cell-active-period *ngSwitchCase="'simpleFilter'"
                           [row]="row"
                           [value]="value"
                           class="table-cell-activePer">
    </iw-cell-active-period>

    <span (mouseenter)="onPreviewMouseOver($event, column)"
          (mouseleave)="onPreviewMouseOver($event)"
          *ngSwitchCase="'preview'"
          class="table-cell-default">
       <span *ngIf="row && row['doctype'] === 'P' || row['doctype'] === 'J'"
             class="fas fa-eye"></span>
    </span>
    <iw-cell-status-rapmisact *ngSwitchCase="'rapMisStatus'"
                              [row]="row"
                              [value]="value"
                              class="table-cell-default"></iw-cell-status-rapmisact>
    <iw-cell-status-client *ngSwitchCase="'cliStatus'"
                           [row]="row"
                           [value]="value"
                           class="table-cell-default"></iw-cell-status-client>
    <iw-cell-status-employee *ngSwitchCase="'empStatus'"
                             [row]="row"
                             [value]="value"
                             class="table-cell-default"></iw-cell-status-employee>
    <iw-cell-status-commandes *ngSwitchCase="'comStatus'"
                              [row]="row"
                              [value]="value"
                              class="table-cell-default"></iw-cell-status-commandes>
    <iw-cell-status-missions *ngSwitchCase="'misStatus'"
                             [row]="row"
                             [value]="value"
                             class="table-cell-default"></iw-cell-status-missions>
    <iw-cell-status-missions *ngSwitchCase="'misStatusRap'"
                             [row]="row"
                             [value]="value | translate"
                             class="table-cell-default"></iw-cell-status-missions>

  </ng-container>
</span>

<p-overlayPanel #previewOp
                [appendTo]="'body'">
    <img [src]="imgToShow"
         alt="document-preview"
         style="width: 25vw">
</p-overlayPanel>
