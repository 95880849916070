import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';
import {buildCustomValueProvider} from '@app/sam-base/base/build-value-provider';
import {trueFalseOperator} from '@app/sam-base/components/iw-grid-column-config/iw-grid-column-config-mappings';
import {RadioItem} from '@app/sam-base/models/components/radio-item.model';

@Component({
    selector: 'iw-radio-list',
    templateUrl: './radio-list.component.html',
    styleUrls: ['./radio-list.component.scss'],
    providers: [buildCustomValueProvider(RadioListComponent)]
})
export class RadioListComponent implements ControlValueAccessor {
    @Input() public radioOptions: RadioItem<string | boolean>[] = [];
    @Input() public orientation: 'vbox' | 'hbox' = 'vbox';
    @Input() public centered = false;
    @Input() public type: 'string' | 'boolean' = 'string';
    @Input() public isGroupDisabled = false;
    @Output() public valueChange = new EventEmitter<string>();
    @Output() public radioSelected = new EventEmitter<string>();

    constructor(private elRef: ElementRef) {
    }

    private _value: any;

    public get value() {
        return this.getValue();
    }

    @Input()
    public set value(val: string) {
        this.writeValue(val);
    }

    public get isInvalid() {
        return this.elRef.nativeElement.classList.contains('ng-invalid');
    }

    public getValue() {
        if (this.type === 'boolean') {
            if (this._value === '') {
                return trueFalseOperator;
            }
        }
        return this._value;
    }

    public writeValue(value: string): void {
        this._value = value;
    }

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: any): void {
        return;
    }

    public onCheck(event: any) {
        if (event || typeof event === 'boolean') {
            this._value = event;
            this.propagateChange(this._value);
            this.valueChange.emit(this._value);
            this.radioSelected.emit(this._value);
        }
    }

    protected propagateChange(value: any) {
    }
}
