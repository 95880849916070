import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from '@root/environments/environment';
import {Gerpl} from '@sam-base/models/invoices/gerpl';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class RappelsService {

    constructor(private _http: HttpClient) {
    }

    public getRappelsTable(facId: string): Observable<Gerpl[]> {
        return this._http.get<Gerpl[]>(environment.backendURL + 'gerpl/' + facId);
    }

    public downloadGerplhdrDoc(id: number) {
        return this._http
            .post(environment.backendURL + `gerplhdr/${id}/download`, id, {
                observe: 'response',
                responseType: 'blob'
            })
            .pipe(map((res: HttpResponse<Blob>) => {
                let blob = new Blob([res.body!], {type: res.headers.get('Content-Type') || ''});
                let fileUrl = URL.createObjectURL(blob);

                let link = document.createElement('a');
                link.href = fileUrl;

                let contentDisposition = res.headers.get('Content-Disposition');
                let filename = contentDisposition?.slice(contentDisposition?.indexOf('=') + 1);
                link.download = filename ?? '';

                link.click();
                URL.revokeObjectURL(link.href);
            }));

    }

}
