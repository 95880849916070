import {IwRestEntity, IwRestEntityId} from '../../core/rest-api';

@IwRestEntity('gepar')
export class Gepar {
    @IwRestEntityId() public nomSoc1?: string;
    public dtadonid?: string;
    public wwwSoc?: string;
    public cptrist?: string;
    public rabMpaId?: string;
    public bvrMpaId?: string;
    public splitecr?: boolean;
    public dtadonadr1?: string;
    public bvrfileext?: string;
    public facfrxpr?: string;
    public zefixNo?: string;
    public poNbj3?: number;
    public rplfoot3?: string;
    public telSoc?: string;
    public facNo?: string;
    public cptrist1?: string;
    public rplhead2?: string;
    public ofsNo?: string;
    public pfxfacno?: string;
    public factors?: boolean;
    public poNbj2?: number;
    public dtadonlieu?: string;
    public pfxfaccyc?: string;
    public tvaId?: string;
    public bvrrefdef?: string;
    public facfrxeml?: string;
    public dateCreat?: string;
    public dateModif?: string;
    public dateTrack?: string;
    public dateDelet?: string;
    public userCreat?: string;
    public userModif?: string;
    public userDelet?: string;
    public txmaxesc1?: number;
    public modCnt?: number;
    public emailSoc?: string;
    public bvrnobnk?: string;
    public dtadonnom?: string;
    public ftrclimode?: number;
    public sdsG?: string;
    public cpttva?: string;
    public adrSoc2?: string;
    public geData?: string;
    public ftrMpaId?: string;
    public paicondId?: string;
    public cptdeb?: string;
    public bvrnoclipl?: boolean;
    public cptrab?: string;
    public letMpaId?: string;
    public autofacNo?: boolean;
    public yearArc?: string;
    public cptprvrist?: string;
    public tvaNo?: string;
    public rplfrxeml?: string;
    public factorsdir?: string;
    public ncfrx?: string;
    public facfrxpf?: string;
    public rplDelta?: number;
    public factorsid?: string;
    public defMpaId?: string;
    public facfrxpt?: string;
    public lieuuc?: boolean;
    public bvrcamtmod?: number;
    public rplOffset?: number;
    public bvrfrx?: string;
    public nomcliuc?: boolean;
    public userId?: string;
    public tvaCode?: string;
    public tvaperiode?: string;
    public rplfrx?: string;
    public lieuSoc?: string;
    public ofsnrSoc?: number;
    public escMpaId?: string;
    public ristCpts?: string;
    public rplReminderDays1?: number;
    public rplReminderDays2?: number;
    public rplReminderDays3?: number;
    public facfrxfa?: string;
    public facfrxyb?: string;
    public rplfoot2?: string;
    public factorspw?: string;
    public bvractif?: boolean;
    public qractif?: boolean;
    public rplsfrx?: string;
    public dtadonnpa?: string;
    public npaSoc?: string;
    public adrSoc1?: string;
    public factorsmod?: number;
    public nomSoc2?: string;
    public txmaxesc2?: number;
    public faxSoc?: string;
    public tvaType?: number;
    public cliadrmode?: number;
    public tvaNoIde?: string;
    public geXmldata?: string;
    public bvrnocli?: string;
    public rplhead3?: string;
    public bvrbydate?: boolean;
    public rplhead1?: string;
    public lprvrist?: boolean;
    public modData?: string;
    public reeNo?: string;
    public dtadonadr2?: string;
    public cptfrais?: string;
    public tvaTaux?: number;
    public rplfoot1?: string;
    public smtpConfigId?: number;
    public emailInvoice?: string;
}


export enum CliMode {
    sans_le_prefix = 1, avec_le_prefix = 2, ID_client = 3,
}

export enum TvaType {
    factures = 1, encaissement = 2,
}
