import {Component, EventEmitter, Input, Output} from '@angular/core';

import {BaseFormControl} from '../../base/base-form-control';
import {buildCustomValueProvider} from '../../base/build-value-provider';

@Component({
    selector: 'iw-radiofield',
    templateUrl: './iw-radiofield.component.html',
    providers: [buildCustomValueProvider(IwRadiofieldComponent)]
})
export class IwRadiofieldComponent<T> extends BaseFormControl<T> {
    @Input() public groupName = 'grp1';

    @Input() public optionValue?: T;
    @Input() public invalid: boolean = false;

    @Output() public check = new EventEmitter<T>();

    public onCheck() {
        if (!this.isDisabled) {
            this.check.emit(this.optionValue);
        }
    }
}
