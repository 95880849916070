<div [class.selection-checkbox]="isSelectionCheckbox"
     class="iw-table flex flex-v">
    <div *ngIf="isDisabled"
         class="iw-table-over"></div>
    <div class="flex-grow iw-table-content">
        <p-table #ptable
                 (onSort)="onSort($event)"
                 [columns]="visibleColumns"
                 [customSort]="true"
                 [dataKey]="dataKey"
                 [id]="dataKey"
                 [lazy]="true"
                 [loading]="tableIsLoading"
                 [resizableColumns]="true"
                 [scrollHeight]="'flex'"
                 [scrollable]="true"
                 [value]="visibleData"
                 [virtualScrollDelay]="virtualScrollDelay"
                 [virtualScrollItemSize]="virtualScrollItemSize"
                 [virtualScroll]="virtualScroll"
                 class="{{customClass}}"
                 styleClass="p-datatable-sm p-datatable-striped">
            <ng-template *ngIf="showCaption"
                         pTemplate="caption">
                <div *ngIf="!isLoading"
                     class="row between-xs">
                    <div *ngIf="!areAllServerEntitiesSelected"
                         class="col-xs-2">
                        <i class="pi pi-check-square"></i> {{ this.selectedIndex?.length }}
                        / {{ this.visibleData?.length }}
                    </div>
                    <div *ngIf="selectAllFromServerEnabled && areAllVisibleEntitiesSelected"
                         class="col-xs-8">
                        <div class="row middle-xs">
                            <div *ngIf="!areAllServerEntitiesSelected"
                                 class="col">
                                {{ 'table_info_select_all_on_this_page' | translate: {size: this.selectedIndex.length} }}
                            </div>
                            <div *ngIf="areAllServerEntitiesSelected"
                                 class="col col-xs-offset-3">
                                {{ 'table_info_select_all' | translate: {size: this.options.totalHits} }}
                            </div>
                            <div *ngIf="!areAllServerEntitiesSelected"
                                 class="col-xs-4">
                                <iw-button (press)="onSelectAllButton()"
                                           [text]="selectAllButtonLabel"
                                           class="iw-button"></iw-button>
                            </div>
                            <div *ngIf="areAllServerEntitiesSelected"
                                 class="col-xs-4">
                                <iw-button (press)="clearSelectSelection()"
                                           [text]="'clear_selection' | translate"
                                           class="iw-button"></iw-button>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-2">
                        <i class="pi pi-eye"></i> {{ visibleData?.length }} / {{ options.totalHits }}
                    </div>
                </div>
            </ng-template>
            <ng-template let-columns
                         pTemplate="header">
                <iw-table-header #tableHeader
                                 (checkboxClick)="onHeaderCheckboxClick()"
                                 (filter)="onFilter($event)"
                                 [columns]="columns"
                                 [hasCheckbox]="isSelectionCheckbox"
                                 [isChecked]="areAllVisibleEntitiesSelected"
                                 [propToGroup]="groupBy"
                                 [rawTableMode]="rawTableMode"
                                 [showFilter]="isFilterEnable"
                                 [showRowGrouping]="showGroups"
                                 [showSortIcon]="isSortIconEnable"></iw-table-header>
            </ng-template>

            <ng-template let-columns="columns"
                         let-expanded="expanded"
                         let-rowData
                         let-rowIndex="rowIndex"
                         pTemplate="body">
                <iw-table-body (checkboxClick)="onCheckboxClick($event)"
                               (contextClick)="onContextMenu($event)"
                               (rowClick)="onRowClick($event)"
                               (rowDoubleClick)="onRowDoubleClick($event)"
                               [columns]="columns"
                               [hasCheckbox]="isSelectionCheckbox"
                               [isChecked]="selectedIndex.indexOf(rowIndex) !== -1"
                               [isClicked]="rowClickedIndex === rowIndex"
                               [isExpanded]="expanded"
                               [items]="visibleData"
                               [prevItem]="visibleData[rowIndex - 1]"
                               [propToGroup]="groupBy"
                               [rawTableMode]="rawTableMode"
                               [rowIndex]="rowIndex"
                               [rowItem]="rowData"
                               [showGridDetails]="showGridDetails"
                               [showRowGrouping]="showGroups"></iw-table-body>
            </ng-template>

            <ng-template let-columns="columns"
                         let-rowData
                         let-rowIndex="rowIndex"
                         pTemplate="rowexpansion">
                <ng-container *ngIf="showGroups">
                    <iw-table-body-row (checkboxClick)="onCheckboxClick($event)"
                                       (contextClick)="onContextMenu($event)"
                                       (rowClick)="onRowClick($event)"
                                       (rowDoubleClick)="onRowDoubleClick($event)"
                                       [columns]="columns"
                                       [hasCheckbox]="false"
                                       [isChecked]="selectedIndex.indexOf(rowIndex) !== -1"
                                       [isClicked]="rowClickedIndex === rowIndex"
                                       [items]="visibleData"
                                       [rowIndex]="rowIndex"
                                       [rowItem]="rowData"
                                       [showGridDetails]="showGridDetails"></iw-table-body-row>
                </ng-container>
            </ng-template>

        </p-table>
    </div>
    <div (click)="onConfigClick()"
         *ngIf="isGridColumnMenuEnable"
         cdkOverlayOrigin
         class="fas iw-config-cog"></div>
</div>
