/** Available form descriptions */
export enum SalaryForm {
    GenreSalaire = 'sags',
    GenreSalaireVersion = 'sagsVersion',
    ParametresGeneraux = 'sapar',
    ParametresGenerauxFacturation = 'gepar',
    ParametresSD4 = 'paramSD4',
    Employee = 'saempview',
    Encaissement = 'gepaicliview',
    PaiementAttribuition = 'paiementattribuition',
    PaiementAttribuitionManyCli = 'paiementattribuitionmanycli',
    SalaryDeductions = 'sapardedview',
    EmployeConjoint = 'employeconjoint',
    EmployeEnf = 'employeenf',
    SadedTaux = 'sadedtaux',
    Sadedda = 'sadedda',
    Saeio = 'saeio',
    SelectionExercise = 'gesaexe',
    DefinitionSalaire = 'sads',
    Sancsmot = 'sancsmot',
    Saeimptx = 'saeimptx',
    Periode = 'sagen',
    FicheSalaire = 'saemsview',
    JourIS = 'jouris',
    FichePaiement = 'paiement',
    Saepview = 'saepview',
    EmployeeDeductionInfo = 'employeeDeductionInfo',
    DecompteGS = 'js3view',
    Repartition = 'sarep',
    Saempop = 'saempop',
    ElementSalaire = 'elemSal',
    ContactNew = 'gecliatt_new',
    ContactEdit = 'gecliatt_edit',
    Client = 'gecliview',
    Assurances = 'assurances',
    PayerProv = 'payerProv',
    Gepaimode = 'gepaimode',
    Geper = 'geper',
    BookingFileList = 'bookingFiles',
    FactureCompta = 'FactureCompta',
    SalaryCompta = 'SalaryCompta',
    FactureNC = 'gefachdr',
    Gefaccliview = 'gefacliview',
    EncaissementManual = 'encaissement_manual',
    Geclisuivi = 'geclisuivi',
    ContactNCS = 'contactncs',
    FactureNoteCreditNew = 'gefachdr_new',
    FaceleEdit = 'gefacele_edit',
    FaceleNew = 'gefacele_new',
    Gepaicond = 'gepaicond',
    Geclicat = 'geclicat',
    DedCreation = 'ded_creation',
    CantonIS = 'canton_IS',
    ParametresNcs = 'saparncs',
    Saparncsmot = 'saparncsmot',
    SaemiIS = 'saemiis',
    SaemiAVS = 'saemiavs',
    SaemiLPP = 'saemilpp',
    Sadedtxage = 'sadedtxage',
    Sapaimode = 'sapaimode',
    PaiementCalculatebtn = 'paiement_calculatebtn',
    CantonBareme = 'canton_bareme',
    Bareme = 'bareme',
    OrdrePaiement = 'gedtaview',
    GerplView = 'gerplhdrview'
}
