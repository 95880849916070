<ng-template #template>
    <tr [class.align-left]="rawTableMode"
        class="iw-table-header"
        id="table-header">
        <ng-container *ngIf="hasCheckbox">
            <th (click)="onHeaderCheckboxClick()"
                class="iw-table-checkbox"
                id="th-checkbox">
                <p-checkbox [disabled]="true"
                            [ngModel]="isChecked"
                            binary="true"></p-checkbox>
            </th>
        </ng-container>
        <th *ngFor="let col of columns"
            [attr.col-name]="col.prop"
            [class.group-column]="col.prop === propToGroup && showRowGrouping"
            [pSortableColumn]="canShowSort(col) ? col.prop : ''"
            [style.width]="col.width ? (col.width || '') + 'px' : '150px'"
            id="th-columns"
            pResizableColumn>
            <div (click)="onFilter(col, $event)"
                 *ngIf="canShowFilter(col)"
                 class="filter"
                 id="filter">
        <span [ngClass]="(col.filterQuery || isStatusFilterActive(col.statusQuery)) ? 'icon-red' : ''"
              class="p-sortable-column-icon pi pi-filter"></span>
            </div>
            <span class="text">{{ (col.name || col.prop) | translate }}</span>
            <p-sortIcon #sortIcon
                        *ngIf="canShowSort(col)"
                        [field]="col.prop"
                        id="sort"></p-sortIcon>
        </th>
    </tr>
</ng-template>
