import {IwColumn} from '@app/sam-base/core/column';
import {IwRestEntity, IwRestEntityId} from '@sam-base/core';

export function gerplStatusColorMapper(row: GerplView): string {
    if (row.noRappel === 1) {
        return '#f3f300'
    }
    if (row.noRappel === 2) {
        return '#fa4c00';
    }
    if (row.noRappel || 0 >= 3) {
        return '#a00101';
    }

    return "#C0C0C0";
}

@IwRestEntity('gerplview')
export class GerplView {
    @IwRestEntityId() @IwColumn({
        type: 'number',
    })
    public id?: number;
    public rpsId?: string;

    public facId?: string;
    public cliId?: string;
    @IwColumn({
        index: 2
    })
    public cliNom?: string;
    @IwColumn({index: 3})
    public ctype?: string;
    @IwColumn({
        index: 1,
        colorMapper: (row: GerplView) => {
            return gerplStatusColorMapper(row);
        }
    })
    public facNo?: string;
    @IwColumn({
        name: 'dateFacture',
        index: 4,
        type: 'date'
    })
    public dateFac?: string;
    @IwColumn({
        name: 'echeance',
        index: 5,
        type: 'date'
    })
    public dateEch?: string;
    @IwColumn({
        index: 6,
        type: 'number'
    })
    public nbjRetard?: number;
    @IwColumn({
        index: 7,
        type: 'mnt'
    })
    public montant?: number;
    @IwColumn({
        index: 8,
        type: 'mnt'
    })
    public solde?: number;
    @IwColumn({
        name: 'norappel',
        index: 4,
        type: 'number'
    })
    public noRappel?: number;
    public status?: number;
    public dateRpl?: string;
    public datePrint?: string;
    public dateMail?: string;
}