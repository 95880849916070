import {createMissionRoles} from '@app/sam-base/core/auth/access-rules/ppemp-context-menu';
import {IwColumn} from '@app/sam-base/core/column';
import {
    IwContextMenuClass,
    IwContextMenuProperty
} from '@app/sam-base/core/context-menu/decorators/iw-context-menu.decorator';
import {IwRestEntity, IwRestEntityId, SearchEntityStatusOperator, SearchExactMatch} from '@app/sam-base/core/rest-api';
import {MenuItem} from '@app/sam-base/models';
import * as clientRoles from '@sam-base/core/auth/access-rules/client-form';
import {ConcubinageType} from '@sam-base/models/common/concubinage-type';
import {Langue} from '@sam-base/models/common/langue.enum';

import {PlacementEvent} from './events.models';


const entityContextMenu: MenuItem[] = [
    {
        label: 'ppemp_contextmenu_sendsms',
        event: PlacementEvent.PPEMP_SEND_SMS,
        accessRoles: clientRoles.clientCommunicationAccessRoles(),
        contextMenuVisibleMode: 'all',
        filter: {allowsms: true}
    },
    {
        label: 'ppemp_contextmenu_sendemail',
        event: PlacementEvent.SEND_EMAIL_EMP,
        accessRoles: clientRoles.clientCommunicationAccessRoles(),
        contextMenuVisibleMode: 'all'
    },
    {
        label: 'ppemp_contextmenu_acomptes',
        event: PlacementEvent.ACOMPTES,
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'ppemp_contextmenu_indemnites',
        event: PlacementEvent.INDEMNITES,
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'ppemp_contextmenu_attestationgain',
        event: PlacementEvent.ATTESTATION_GAIN_INTERMEDAIRE,
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'ppemp_contextmenu_attestationemployer',
        event: PlacementEvent.ATTESTATION_EMPLOYER,
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'ppemp_contextmenu_print',
        event: PlacementEvent.PRINT_REPORT_EMP,
        accessRoles: clientRoles.contextMenuPrintRoles(),
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'contextmenu_suivi',
        event: PlacementEvent.PPSUI_NEW,
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'contextmenu_mission',
        event: PlacementEvent.PPMISS_NEW,
        contextMenuVisibleMode: 'single',
        accessRoles: createMissionRoles()
    },
    {
        label: 'contextmenu_plac_fixe',
        event: PlacementEvent.PLAC_FIXE,
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'context_menu_mission',
        event: PlacementEvent.PPEMP_OPEN_MISSION,
        contextMenuVisibleMode: 'single'
    },
    {
        label: 'context_menu_rapports',
        event: PlacementEvent.PPEMP_OPEN_RAPPORTS,
        contextMenuVisibleMode: 'single'
    },
    {
        separator: true
    }];

@IwContextMenuClass(...entityContextMenu) @IwRestEntity('ppemp')
export class Ppemp {
    @IwColumn({
        name: 'id',
        index: 0,
        type: 'empStatus'
    }) @IwRestEntityId() public empId?: string;
    public empNo?: string;
    public noGestion?: string;
    public cempstatus?: string;
    @IwColumn({
        name: 'status',
        index: 1,
        type: 'status',
        valueFormat: 'ppemp_status_',
        filterQuery: '',
        statusQuery: [],
        filterOperator: SearchEntityStatusOperator.NotInclude
    }) public cempstatustext?: string;
    @IwColumn({
        name: 'politesse',
        index: 2,
        hidden: true
    }) public cpolitesse?: string;
    @IwContextMenuProperty({label: 'Nom Action'}) @IwColumn({
        name: 'nom',
        index: 3
    }) public nom?: string;
    @IwColumn({
        name: 'prenom',
        index: 4
    }) public prenom?: string;
    @IwColumn({
        name: 'adresse',
        index: 5,
        hidden: true
    }) public adresse1?: string;
    @IwColumn({
        name: 'comp_adresse',
        index: 6,
        hidden: true
    }) public adresse2?: string;
    @IwColumn({
        name: 'npa',
        index: 7,
        hidden: true,
        width: 70
    }) public npa?: string;
    @IwColumn({
        name: 'lieu',
        index: 8
    }) public lieu?: string;
    @IwColumn({
        name: 'pays',
        index: 9,
        hidden: true,
        width: 70
    }) public pays?: string;
    @IwColumn({
        name: 'datenaiss',
        index: 10,
        type: 'date'
    }) public datenaiss?: string;
    @IwColumn({
        index: 11,
        hidden: true
    }) public noavs13?: string;
    @IwColumn({
        name: 'profession',
        type: 'keyword',
        index: 12
    }) public profession?: string;
    @IwColumn({
        name: 'permis',
        index: 13,
        hidden: true
    }) public permis?: string;
    @IwColumn({
        name: 'permisno',
        index: 14,
        hidden: true
    }) public permisno?: string;
    @IwColumn({
        name: 'perm_dtde',
        index: 15,
        type: 'date',
        hidden: true
    }) public permDtde?: string;
    @IwColumn({
        name: 'perm_dta',
        index: 16,
        type: 'date',
        hidden: true,
    }) public permDta?: string;
    @IwColumn({
        name: 'tel1',
        type: 'phonenumber',
        index: 17,
        hidden: true,
        indexed: false
    }) public tel1?: string;
    @IwColumn({
        name: 'natel',
        type: 'phonenumber',
        index: 18
    }) public natel?: string;
    @IwColumn({
        name: 'email',
        type: 'keyword',
        index: 19
    }) public email?: string;
    @IwColumn({
        name: 'langue0',
        index: 20,
        hidden: true
    }) public langue0?: string;
    @IwColumn({
        name: 'langue00',
        index: 21,
        hidden: true
    }) public langue00?: string;
    @IwColumn({
        name: 'langue1',
        index: 22,
        hidden: true
    }) public langue1?: string;
    @IwColumn({
        name: 'langue2',
        index: 23,
        hidden: true
    }) public langue2?: string;
    @IwColumn({
        name: 'langue3',
        index: 24,
        hidden: true
    }) public langue3?: string;
    @IwColumn({
        name: 'langue4',
        index: 25,
        hidden: true
    }) public langue4?: string;
    @IwColumn({
        name: 'date_modif',
        index: 26,
        type: 'dateTime'
    }) public dateModif?: string;
    @IwColumn({
        name: 'regions',
        index: 27,
        hidden: true
    }) public regions?: string;
    @IwColumn({
        name: 'lastJob',
        index: 28,
        indexed: false,
        hidden: true
    }) public lastjob?: string;
    @IwColumn({
        name: 'parcours',
        index: 29,
        hidden: true
    }) public parcours?: string;
    @IwColumn({
        name: 'apprent',
        index: 30,
        hidden: true
    }) public apprent?: string;
    @IwColumn({
        name: 'certificats',
        index: 31,
        hidden: true
    }) public certificat?: string;
    @IwColumn({
        name: 'note',
        index: 32,
        hidden: true
    }) public note?: string;
    @IwColumn({
        name: 'appreciationRating',
        index: 33,
        hidden: true
    }) public apprec?: string;
    @IwColumn({
        name: 'usertag',
        index: 34,
        hidden: true
    }) public usertag?: string;
    @IwColumn({
        name: 'ageId',
        index: 35,
        hidden: true
    }) public ageId?: string;
    @IwColumn({
        name: 'nomConseiller',
        index: 36,
        hidden: true
    }) public nomconseiller?: string;
    @IwColumn({
        name: 'date_creat',
        index: 37,
        hidden: true,
        type: 'dateTime'
    }) public dateCreat?: string;
    @IwColumn({
        name: 'vehPerNote',
        index: 38,
        hidden: true
    }) public vehpernote?: string;
    @IwColumn({
        name: 'vehNote',
        index: 39,
        hidden: true
    }) public vehNote?: string;

    @IwColumn({
        type: 'matching',
        name: 'Score',
        hidden: true,
        index: -1
    }) public matchNeedPercentage?: number;

    public datedossie?: string;
    public noavs?: string;
    public lastMis?: string;
    public firstRap?: string;
    public allowsms?: boolean;
    public allowemail?: boolean;
    public sexe?: string;
    public langue?: Langue;
    public dateDelet?: string;
    public dtAnyModif?: string;
    public empstatus?: number;
    public conjNaiss?: string;
    public enfnballoc?: number;
    public webfrstexp?: string;
    public soumisis?: boolean;
    public imis2ijmod?: number;
    public conjNom?: string;
    public dipl5?: boolean;
    public lpervoi?: boolean;
    public nextSm?: string;
    public conjnoas13?: string;
    public webimpnote?: string;
    public salgenfreq?: number;
    public enfConcub?: boolean;
    public requerant?: boolean;
    public lvoiture?: boolean;
    public lweb?: boolean;
    public svceMil?: boolean;
    public aptId?: string;
    public lperd1?: boolean;
    public weblastexp?: string;
    public lnoaco?: boolean;
    public geoLat?: number;
    public orpquaId?: string;
    public lan3Lu?: number;
    public poursuites?: boolean;
    public conjprof?: string;
    public diplbin?: number;
    public apt8?: boolean;
    public lan2Lu?: number;
    public aptbin?: number;
    public lperchan?: boolean;
    public nobenalloc?: string;
    public conjFi?: boolean;
    public dipl2?: boolean;
    public cmAdr?: string;
    public forcelpp?: boolean;
    public lastAct?: string;
    public origine?: string;
    public lanData?: string;
    public lppstart0?: string;
    public lppheucnt1?: number;
    public postdesire?: string;
    public quaId?: string;
    public firstMis?: string;
    public typejob?: number;
    @IwColumn({
        name: 'listeNoire',
        index: 40,
        type: 'boolean',
        hidden: true,
        filterQuery: 'false',
        filterOperator: SearchExactMatch.Equals
    }) public listenoir?: boolean;
    public rating?: number;
    public etatcivil?: string;
    public modCnt?: number;
    public agiFrom?: string;
    public geoLon?: number;
    public ofsnr?: number;
    public profFrom?: string;
    public tel2?: string;
    public banqueSwi?: string;
    public extrcasier?: boolean;
    public firstAct?: string;
    public caissemal?: string;
    public localDate?: string;
    @IwColumn({
        name: 'iban',
        index: 41,
        hidden: true
    }) public iban?: string;
    public websource?: string;
    public conjWork?: boolean;
    public lppstart1?: string;
    public lchomage?: boolean;
    public lanNote?: string;
    public saldesire?: string;
    public lppsemcnt0?: number;
    public lperrem?: boolean;
    public dateEng?: string;
    public conjEmpl?: string;
    public keywords?: string;
    public lan2Ecrit?: number;
    public concuctxt?: string;
    public enfMenind?: boolean;
    public banqueCpt?: string;
    public modecreat?: string;
    public cempstatu3?: string;
    public enfnb?: number;
    public lpermoto?: boolean;
    public lvelo?: boolean;
    public pregnant?: boolean;
    public conjWkch?: boolean;
    public lan1Lu?: number;
    public info4?: number;
    public webFin?: string;
    public agi?: boolean;
    public lmoto?: boolean;
    public info3?: number;
    public webimpdate?: string;
    public opNote?: string;
    public webimpdata?: string;
    public concuc?: boolean;
    public info5?: number;
    public primId?: number;
    public ppempReferences?: string;
    public modData?: string;
    public urgence?: string;
    public apt6?: boolean;
    public dipl3?: boolean;
    public etatsante?: string;
    public lppheucnt0?: number;
    public conjWkdeb?: string;
    public conjWkfin?: string;
    public datetatciv?: string;
    public lan3Parle?: number;
    public congdesire?: string;
    public acofact?: number;
    public typehor?: number;
    public lan2Parle?: number;
    public conjCh?: boolean;
    public imgfname?: string;
    public confession?: string;
    public lan1Ecrit?: number;
    public info1?: number;
    public misId?: string;
    public info6?: number;
    public noteBo?: string;
    public lieunaiss?: string;
    public apt3?: boolean;
    public apt9?: boolean;
    public typehortxt?: string;
    public lan4Ecrit?: number;
    public inquiry?: boolean;
    public apt1?: boolean;
    public lppsemcnt1?: number;
    public apt2?: boolean;
    public lppforce1?: boolean;
    public opMois?: number;
    public info2?: number;
    public ppempfrx?: string;
    public profOther?: string;
    @IwColumn({
        name: 'empintype',
        index: 42,
        hidden: true
    })
    public empintype?: string;
    public opVital?: number;
    public bstoemail?: boolean;
    public nation?: string;
    public lrgdp?: boolean;
    public photo?: string;
    public lan3Ecrit?: number;
    public webimpfile?: string;
    public lexp2ij?: boolean;
    public dipl4?: boolean;
    public horaires?: string;
    public apt10?: boolean;
    public cmotifnoir?: string;
    public lallocs?: boolean;
    public userId?: string;
    public souhaits?: string;
    public lperpl?: boolean;
    public conjpedta?: string;
    public preavis?: string;
    public apt7?: boolean;
    public lperbus?: boolean;
    public dipl1?: boolean;
    public lconjalloc?: boolean;
    public empData?: string;
    public apt5?: boolean;
    public conjpermis?: string;
    public caissecho?: string;
    public lsunet?: boolean;
    public disponible?: string;
    public dateccadre?: string;
    public textSm?: string;
    public banqueAdr?: string;
    public apt4?: boolean;
    public syncflag?: number;
    public permNote?: string;
    public casier?: boolean;
    public banqueClr?: string;
    public nomjf?: string;
    public dtatype?: number;
    public pregtexte?: string;
    public banque?: string;
    public ijData?: string;
    public conjprenom?: string;
    public lan4Lu?: number;
    public xmlData?: string;
    public cempstatu2?: string;
    public lan4Parle?: number;
    public lan1Parle?: number;
    public paytype?: number;
    public cdfId?: string;
    public conjnation?: string;
    public enfnbcharg?: number;
    public empaptId?: string;
    public userCreat?: string;
    public userDelet?: string;
    public userModif?: string;
    public ppcon?: string;
    public conId?: string;
    public ppagence?: string;
    public datedossier?: string;
    public remarques?: string;
    public conjisch?: boolean;
    public empKeywords?: string[];
    public professions?: string[];
    public absenses?: string[];
    public lppwcount?: number;
    public swissdecFormation?: string;
    public swissdecPosition?: string;
    public swissdecConfession?: string;
    public concubType?: ConcubinageType;
}
