import {SortDirection, TableSortEvent} from '@sam-base/models';
import {SearchEntityOperator} from '../../core/rest-api';
import {MenuItem} from './menu-item.model';

export type IwColumnDataType =
    | 'string'
    | 'number'
    | 'date'
    | 'dateTime'
    | 'translate'
    | 'preview'
    | 'status'
    | 'enum'
    | 'cliStatus'
    | 'empStatus'
    | 'comStatus'
    | 'misStatus'
    | 'rapMisStatus'
    | 'activePer'
    | 'dateDebut'
    | 'matching'
    | 'weekday'
    | 'timeslot'
    | 'misStatusRap'
    | 'cdeType'
    | 'boolean'
    | 'warning'
    | 'docTranslate'
    | 'mnt'
    | 'simpleFilter'
    | 'phonenumber'
    | 'salId'
    | 'keyword';

export interface IwGridColumn<T> {
    name?: string;
    prop?: Extract<keyof T, string | number>;
    icon?: string;
    type?: IwColumnDataType;
    decimals?: number;
    valueFormat?: string;
    hidden?: boolean;
    contextMenu?: MenuItem[];
    index?: number;
    width?: number;
    statusQuery?: boolean[];
    filterQuery?: string;
    filterQuery2?: string;
    filterOperator?: SearchEntityOperator;
    // Makes the column filtrable/sortable
    indexed?: boolean;
    align?: 'left' | 'right' | 'center';
    // add a colorMapper attribut that is a callback
    colorMapper?: (row: any) => string;
    enumPrefix?: string;
}

export interface IwGridFilter<T> {
    column: IwGridColumn<T>;
    value: any;
    operator: SearchEntityOperator;
}

function getDataTypeForSorting(columnDataType: IwColumnDataType): 'string' | 'number' | 'date' | 'boolean' {
    switch (columnDataType) {
        case 'string':
        case 'translate':
        case 'preview':
        case 'status':
        case 'enum':
        case 'cliStatus':
        case 'empStatus':
        case 'comStatus':
        case 'misStatus':
        case 'rapMisStatus':
        case 'dateDebut':
        case 'matching':
        case 'weekday':
        case 'timeslot':
        case 'misStatusRap':
        case 'cdeType':
        case 'docTranslate':
        case 'simpleFilter':
        case 'phonenumber':
        case 'salId':
        case 'keyword':
            return 'string';
        case 'mnt':
        case 'number':
            return 'number';
        case 'date':
        case 'dateTime':
            return 'date';
        case 'boolean':
        case 'warning':
            return 'boolean'
        default:
            throw new Error(`Unsupported IwColumnDataType: ${columnDataType}`);
    }
}

function compareString<T>(a: T, b: T, prop: Extract<keyof T, string | number>) {
    let aProp: string = String(a[prop]);
    let bProp: string = String(b[prop]);
    return aProp > bProp ? 1 : aProp < bProp ? -1 : 0;
}

// Making sure non dates are always at the end
const normalizeDate = (value: any, order: SortDirection) => {
    if (value == null || isNaN(Date.parse(value))) {
        return order === 'asc' ? Infinity : -Infinity;
    }
    return Date.parse(value);
};

function compareDate<T>(a: T, b: T, prop: Extract<keyof T, string | number>, order: SortDirection) {
    const aProp = normalizeDate(a[prop], order);
    const bProp = normalizeDate(b[prop], order);
    return aProp - bProp;
}

// Making sure non numbers are always at the end
const normalizeNumber = (value: any, order: SortDirection) => {
    if (value == null || (typeof value === 'string' && value.trim() === '') || isNaN(Number(value))) {
        return order == 'asc' ? Infinity : -Infinity;
    }
    return Number(value);
};

function compareNumber<T>(a: T, b: T, prop: Extract<keyof T, string | number>, order: SortDirection) {
    const aProp = normalizeNumber(a[prop], order);
    const bProp = normalizeNumber(b[prop], order);
    return aProp - bProp;
}

function compareBoolean<T>(a: T, b: T, prop: Extract<keyof T, string | number>) {
    let aProp: boolean = Boolean(a[prop]);
    let bProp: boolean = Boolean(b[prop]);
    return aProp === bProp ? 0 : aProp ? 1 : -1;
}

export function sortGridList<T>(list: T[], event: TableSortEvent<T>) {
    const prop = event.column.prop!;
    const columnDataType = getDataTypeForSorting(event.column.type!);
    list.sort((a: T, b: T) => {
        let comparison = 0;
        if (columnDataType === "number") {
            comparison = compareNumber(a, b, prop, event.newValue);
        } else if (columnDataType === "string") {
            comparison = compareString(a, b, prop);
        } else if (columnDataType === "date") {
            comparison = compareDate(a, b, prop, event.newValue);
        } else if (columnDataType === "boolean") {
            comparison = compareBoolean(a, b, prop);
        }
        return event.newValue === 'asc' ? comparison : -comparison;
    });
    return list;
}

