import {IwColumn, IwRestEntity, IwRestEntityId} from '@sam-base/core';
import {SendingStatus} from '@sam-base/models/common/sending-status.enum';
import {Gerpl} from '@sam-base/models/invoices/gerpl';

@IwRestEntity('gerplhdr')
export class Gerplhdr {
    @IwRestEntityId() @IwColumn()
    public rplhdrId?: number;
    public cliId?: string;
    @IwColumn()
    public cliNom?: string;
    public rplAdrId?: string;
    public rplAttn?: string;
    @IwColumn()
    public noRappel?: number;
    @IwColumn({
        type: 'mnt',
        align: 'right',
    })
    public solde?: number;
    @IwColumn()
    public dateRpl?: string;
    public datePrint?: string;
    public dateMail?: string;
    public sendingStatus?: SendingStatus;

    public gerplList: Gerpl[] = [];
    public dateCreat?: string;
    public dateModif?: string;
    public dateTrack?: string;
    public dateDelet?: string;
    public userCreat?: string;
    public userModif?: string;
    public userDelet?: string;
}
