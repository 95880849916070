export enum LayoutEvents {
    TOGGLE_SLIDE_PANEL = 'layout#slide_panel_toggle',
    TOGGLE_SLIDE_PANEL_ON = 'layout#slide_panel_toggleOn',
    TOGGLE_SLIDE_PANEL_OFF = 'layout#slide_panel_toggleOff',

    TOGGLE_SIDE_MENU = 'layout#side_menu_toggle',

    TOGGLE_SIDE_MENU_CONFIG = 'layout#side_menu#config',

    TOGGLE_MENU = 'layout#menu#toggle',

    TOGGLE_GLOBAL_SLIDE = 'layout#global_slide#toggle',
    TOGGLE_GLOBAL_SLIDE_ON = 'layout#global_slide#toggleOn',
    TOGGLE_GLOBAL_SLIDE_OFF = 'layout#global_slide#toggleOff',
}

export enum LayoutRootNavigationEvent {
    BAKCOFFICE = 'root#layout#backoffice', FRONTOFFICE = 'root#layout#frontoffice',
}

export const LayoutEventNone: string = '';

export enum LayoutPlacementNavigationEvent {
    CLIENT = 'layout#nav#client',
    EMPLOY = 'layout#nav#employ',
    ORDERS = 'layout#nav#orders',
    ORDERS_FIXE = 'layout#nav#orders_fixe',
    ASSIGNMENTS = 'layout#nav#assignment',
    PLACEMENT = 'layout#nav#placement',
    DASHBOARD = 'layout#nav#dashboard', // SALARY
}

export enum LayoutAdminNavigationEvent {
    SWISSDEC = 'layout#admin#swissdec',
    KUSERS = 'layout#admin#kusers',
    ULTIMATE_ADMIN = 'layout#admin#ultimate_admin',
    OPERATIONS = 'layout#admin#operations',
    BULK_MANAGER = 'layout#admin#bulk_manager'
}

export enum LayoutSalaryNavigationEvent {
    DASHBOARD = 'layout#nav#salary#dashboard',
    EMPLOYEE = 'layout#nav#salary#employee',
    FICHE_SALAIRE = 'layout#nav#salary#fiche_salaire',
    CLIENT = 'layout#nav#salary#client',
    FACTURE = 'layout#nav#salary#facture',
    ENCAISSEMENT = 'layout#nav#salary#encaissement',
    PAIEMENT = 'layout#nav#salary#paiement',
    ORDRE_PAIEMENT = 'layout#nav#salary#ordre_paiement',
}


export enum LayoutSubNavigationEvent {
    RAPPORTS = 'layout#sub_nav#rapports',
    TEXTES_PARAMETRABLES = 'layout#sub_nav#config#textes_parametrables',
    PARAMETRES_GENERAUX = 'layout#sub_nav#parametresGeneraux',
    CALENDAR_JOURS_FERIES = 'layout#sub_nav#calendar_jours_feries',
    KIBANA = 'layout#sub_nav#kibana',
    KIBANA_FOLLOWUP_MANAGER = 'layout#sub_nav#kibana_followup_manager',
    KIBANA_MARGIN_PROFIT = 'layout#sub_nav#kibana_margin_profit',
    KIBANA_RESUME = 'layout#sub_nav#kibana_resume',
    KIBANA_RECOUVREMENT = 'layout#sub_nav#kibana_recouvrement',
    KIBANA_ADVISOR_ACTIVITY = 'layout#sub_nav#kibana_advisor_activity',
    CONSEILLERS = 'layout#sub_nav#conseillers',
    PERIODE_ACTIVE = 'layout#sub_nav#periode_active',
    MODELES_MISSION = 'layout#sub_nav#modeles_mission',
    ACOMPTES = 'layout#sub_nav#acomptes',
    INDEMNITES = 'layout#sub_nav#indemnites',
    GENRES_INDEMNITES = 'layout#sub_nav#genres_indemnites',
    PROF_CONFIG = 'layout#sub_nav#prof_config',
    KEYWORD_CONFIG = 'layout#sub_nav#keyword_config',
    PPGRH_CONFIG = 'layout#sub_nav#ppgrh_config',
    PPSECTEUR_CONFIG = 'layout#sub_nav#ppsecteur_config',
    PPCCT_CONFIG = 'layout#sub_nav#ppcct_config',
    PPDOC_TYPE = 'layout#sub_nav#ppdoc_type',
    INVOICES_PREPARATION = 'layout#sub_nav#invoices_preparation',
    INVOICES_PUBLICATION = 'layout#sub_nav#invoices_publication',
    INVOICES_EMAIL = 'layout#sub_nav#invoices_email',
    SEND_INVOICE = 'layout#sub_nav#send_invoice',
    HEURES_ALLOCATIONS_FAMILIALES = 'layout#sub_nav#allocations_familiales',
    CONTRACTS = 'layout#sub_nav#contracts',
    DECLPP = 'layout#sub_nav#declpp',
    DEBUTS = 'layout#sub_nav#debuts',
    RAPPORTS_SAISIS = 'layout#sub_nav#rapports_saisis',
    RAPPORTS_MANQUANTS = 'layout#sub_nav#rapports_manquants',
    EMP_DOCS = 'layout#sub_nav#emp_docs',
    SWISSSTAFFING = 'layout#sub_nav#swissstaffing',
    SALARY_PREPARATION = 'layout#sub_nav#salary_preparation',
    AGINTER = 'layout#sub_nav#aginter',
    GESTION_MODELES_FACTURATION = 'layout#sub_nav#gestion_modeles_facturation',
    GESTION_MODELES_PLACEMENT = 'layout#sub_nav#gestion_modeles_placement',
    LOOKUPS = 'layout#sub_nav#lookups',
    GESTION_MODELES_SALAIRE = 'layout#sub_nav#gestion_modeles_salaire',
    GENRE_TRAVAIL = 'layout#sub_nav#genre_travail',
    ATTESTATION_EMPLOYER = 'layout#sub_nav#attestation_employer',
    SITDEBITEUR = 'layout#sub_nav#sitdebiteur',
    BALDEBITEUR = 'layout#sub#baldebiteur',
    CACLIENT = 'layout#sub_nav#caclient',
    ACTIVE_MISSIONS = 'layout#sub_nav#active_missions',
    NEW_CLIENT_DECOMPTE = 'layout#sub_nav#new_client_decompte',
    RELEVE_COMPTES_DEBITEURS = 'layout#sub_nav#releve_compte_debiteurs',
    RELEVE_COMPTES_DEBITEURSCLI = 'layout#sub_nav#releve_compte_debiteurs',
    WEEKLY_TIMESHEETS_DECOMPTE = 'layout#sub_nav#weekly_timesheets_decompte',
    RAPPORT_LOCATION_SERVICE = 'layout#sub_nav#rapport_location_service',
    RAPPORT_ANNUEL = 'layout#sub_nav#rapport_annuel',
    HEURES_PRODUITES = 'layout#sub_nav#heures_produites',
    SITDEBITEURCLI = 'layout#sub_nav#sitdebiteurcli',
    USER_ROLES = 'layout#sub_nav#user_roles',
    USER_TEAMS = 'layout#sub_nav#user_teams',
    IJ_CONFIG = 'layout#sub_nav#ij_config',
    CALENDAR_EXP = 'layout#sub_nav#calendar_exp',
    MISSION_HISTORY = 'layout#sub_nav#mishist',
    CERT_TRAVAIL = 'layout#sub_nav#cert_travail',
    RECAP_MIS = 'layout#sub_nav#recap_mis',
    ABS = 'layout#sub_nav#abs',
    ACOLIST = 'Layoubt#sub_nav#acolist',
    SUVA = 'Layoubt#sub_nav#suva',
    MARGIN = 'Layoubt#sub_nav#margin',
    TIMESHEET = 'Layoubt#sub_nav#timesheet',
    TIMESHEET_EMP = 'Layoubt#sub_nav#timesheet',
    ECHEANCE_PERMIS = 'Layoubt#sub_nav#echeance_permis',
    PPSUIVI = 'layout#sub_nav#suivi',
    // SALARY
    PERIODE = 'layout#sub_nav#periode',
    SELECTION_EXERCISE = 'layout#sub_nav#selection_exercise',
    RECALCIO = 'layout#admin#recalcio',
    ATTESTATION_QUITANCE = 'layout#sub_nav#attestation_quitance',
    DECOMPTE_ANNUEL = 'layout#sub_nav#decompte_annuel',
    DECOMPTE_IS = 'layout#sub_nav#decompte_is',
    NCS = 'layout#sub_nav#ncs',
    ANNOCE_AVS = 'layout#sub_nav#annoce_avs',
    GENRE_DE_SALAIRE = 'layout#sub_nav#genere_de_salaire',
    SAPAIMODE = 'layout#sub_nav#sapaimode',
    SALARY_DEDUCTION = 'layout#sub_nav#salaryDeduction',
    AGENCE_CONFIG = 'layout#sub_nav#agency_config',
    JOURNAUX = 'layout#sub_nav#journaux',
    GSRECAPANNUEL = 'layout#sub_nav#gsrecalannuel',
    AFRECAPANNUEL = 'layout#sub_nav#afrecalannuel',
    PAIEMENTS = 'layout#sub_nav#paiements',
    PERMIS = 'layout#sub_nav#permis',
    LPPNONPAYEE = 'layout#sub_nav#decomptelppnonpayee',
    DECOMPTEENTREPRISE = 'layout#sub_nav#decompteentreprise',
    LISTEDOSSIEREMPLOYES = 'layout#sub_nav#listedossieremployes',
    ASSURANCES = 'layout#sub_nav#assurances',
    GEPER = 'layout#sub_nav#geper',
    BOOKING_FILES_INVOICE = 'layout#sub_nav#booking_files#invoice',
    BOOKING_FILES_SALARY = 'layout#sub_nav#booking_files#salary',
    GEPAIMODE = 'layout#sub_nav#gepaimode',
    GEPAICOND = 'layout#sub_nav#gepaicond',
    GECLICAT = 'layout#sub_nav#geclicat',
    PARAMETRES_GENERAUX_FACTURATION = 'layout#sub_nav#parametresGenerauxFacturation',
    PARAMETRES_GENERAUX_SALARY = 'layout#sub_nav#parametresGenerauxSalary',
    DECOMPTEGS = 'layout#sub_nav#decomptegs',
    DECOMPTECCT = 'layout#sub_nav#decomptecct',
    SALARYDEF = 'layout#sub_nav#definitionsalaire',
    CANTON_IS = 'layout#sub_nav#decompte_is',
    PARAMETRES_NCS = 'layout#sub_nav#parametresNcs',
    CANTON_BAREME = 'layout#sub_nav#canton_bareme',
    GS_ANNUEL_REVISIONS = 'layout#sub_nav#gsannuelrevisions',
    RAPPELS_FACTURES = 'layout#sub_nav#rappels_factures',
    RAPPEL_HEADER = 'layout#sub_nav#rappel_header',
    RAPPELS_CREATION = 'layout#sub_nav#rappels_creation',
    INVOICE_PRINT_REQUEST = 'layout#sub_nav#invoice_print_request',

}
