import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {RestApiService, RestQueryOperation, RestQueryParam} from '@app/sam-base/core/rest-api';
import {Ppcon} from '@app/sam-base/models/placement';
import {environment} from '@root/environments/environment';
import {Ppemp} from '@sam-base/models/placement';
import {ProfileService} from '@shared/profile/profile.service';
import {UserProfile} from '@shared/profile/user-profile.model';
import {lastValueFrom, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

import {UserConseillers} from '../../models/placement/ppconModels';

@Injectable()
export class PpconService {

    constructor(private _restService: RestApiService, private _profileService: ProfileService,
                private _http: HttpClient) {
    }

    /**
     * Function to get the associated conseillers (by user and it's role)
     */
    public async getLoggedUserConseillers(): Promise<Ppcon[]> {
        try {
            return await lastValueFrom(this._http.get<Ppcon[]>(environment.backendURL + 'conseillers/all-linked'));
        } catch (error) {
            return ([]);
        }
    }

    /**
     * Function to get the associated conseillers (by user and it's role)
     */
    public async getAllConseillers(): Promise<Ppcon[]> {
        try {
            return await lastValueFrom(this._http.get<Ppcon[]>(environment.backendURL + 'conseillers/full-list'));
        } catch (error) {
            return ([]);
        }
    }

    /**
     * Function to get the associated conseillers (by user and it's role)
     */
    public getLoggedUserConseillersParsed(): Observable<UserConseillers> {
        return this._http.get<UserConseillers>(environment.backendURL + 'user/conseillers');
    }

    /**
     * Function to get ONLY the main conseiller and the seconday conseillers
     * associated with the logged user.
     */
    public async getLoggedUserConseillersOnly(): Promise<any> {
        return lastValueFrom(this._http.get<any>(environment.backendURL + 'conseillers/loggedUser'))
            .then((list: any) => {
                if (list && list._embedded) {
                    return list._embedded.ppcon;
                }
                return [];
            });
    }

    public getRemoveUnlicensedUsers(): Observable<void> {
        return (this._http.delete<void>(environment.backendURL + 'sam-user/remove-unused-users'));
    }

    public getMainConseiller(): Observable<Ppcon | undefined> {
        return this.getUserId()
            .pipe(switchMap(userId => this._restService.getEntityQuery(Ppcon, {
                operation: RestQueryOperation.Equals,
                prop: 'samuserId',
                value: userId
            })
                .scroll()
                .pipe(map(e => e && e[0]))));
    }

    public getUserId(): Observable<string> {
        return this._profileService.loadProfile()
            .pipe(map((userProfile) => userProfile.userId));
    }

    public async getEmployeesByUserConseillers(): Promise<Ppemp[]> {
        const userCons: string[] = (await this.getLoggedUserConseillers())
            .map((conseiller: Ppcon) => conseiller.conId)
            .filter((elem): elem is string => typeof elem === 'string');
        if (!userCons.length) {
            return [];
        }
        const ppempQuery: RestQueryParam<Ppemp, string> = {
            operation: RestQueryOperation.In,
            prop: 'conId',
            value: userCons
        };
        const ppempScroll = this._restService.getEntityQuery(Ppemp, ppempQuery);
        ppempScroll.setPageSize(10000);
        return await lastValueFrom(ppempScroll.scroll());
    }

    public getFullConseillerList(): Observable<Ppcon[]> {
        return (this._http.get<Ppcon[]>(environment.backendURL + 'conseillers/full-list'));
    }

    public hasAuthorizedConseiller(template: string, id: string, params: any | undefined): Observable<boolean> {
        return (this._http.post<boolean>(environment.backendURL + `report/canCreateReport/${template.toUpperCase()}/${id}`, params));
    }

    public fillMainConseiller(control: AbstractControl | undefined): void {
        if (!control) {
            return;
        }
        this._profileService.loadProfile()
            .subscribe((user: UserProfile) => {
                this.getMainCons(user, control);
            });
    }

    public async getMainCons(user: UserProfile, control: AbstractControl | undefined) {
        if (!control) {
            return;
        }
        if (user.groups.includes('pp-usr_cons')) {
            const conseillers: UserConseillers = await lastValueFrom(this.getLoggedUserConseillersParsed());
            if (conseillers.mainConseiller && conseillers.mainConseiller.conId) {
                control.setValue(conseillers.mainConseiller.conId);
            }
        }
    }
}
