import {PrintRequestType} from '@modules/sam-main/admin/models/print-request.model';
import {GefacStatus} from '@sam-base/models/invoices/gefacliview';
import {GepaicliPayStatus} from '@sam-base/models/invoices/gepaicliview';
import {ClientStatus} from '@sam-base/models/placement/client-status';
import {EmployeeStatus} from '@sam-base/models/placement/employee-status';

export class EntityStatusMap {
    private static map: EntityMap = {
        ppemp_status_: [
            EmployeeStatus.NEW,
            EmployeeStatus.INACTIVE_WITH_SUIVI,
            EmployeeStatus.INACTIVE_HAS_WORKED,
            EmployeeStatus.IN_MISSION,
            EmployeeStatus.BLOCKED,
            EmployeeStatus.DEACTIVATED,
            EmployeeStatus.CANDIDATE],
        ppcli_status_: [
            ClientStatus.NEW,
            ClientStatus.ACTIVE,
            ClientStatus.INACTIVE,
            ClientStatus.BLOCKED,
            ClientStatus.OTHER],
        encaissement_status_: [
            GepaicliPayStatus.OPEN,
            GepaicliPayStatus.CLOSE,
            GepaicliPayStatus.WRONG,
        ],
        facture_status_: [
            GefacStatus.OPEN,
            GefacStatus.CLOSE,
            GefacStatus.WRONG,
        ],
        ppmis_status_: [
            1,
            2,
            3,
            4,
            8],
        ppcde_status_: [
            0,
            1,
            2],
        ppcdf_status_: [
            0,
            1,
            2],
        ppmis_duration_status_: [
            0,
            1],
        ppmis_salmode_: [
            1,
            3,
            'autre'],
        ppmis_facmode_: [
            1,
            3,
            'autre'],
        saemp_: [
            'inactive',
            'emp_not_ok',
            'active',
            'working_year'],
        saparded_: [
            'true',
            'false'],
        gecli_: [
            '2',
            'not_2'],
        gefacliview_: [
            'status_0',
            'status_1',
            'status_5',
            'status_99'],
        print_request_type: [
            PrintRequestType.INVOICE,
            PrintRequestType.INVOICE_REMINDER,
            PrintRequestType.SALARY],
    };

    public static getStatusValue(t: keyof EntityMap): (number | string)[] {
        return EntityStatusMap.map[t] || [];
    }


    public static getSelectedValues(t: keyof EntityMap, values: boolean[]): (number | string)[] {
        return EntityStatusMap.getStatusValue(t).filter((_, i) => values[i]);
    }

    public static getFilterConfigFrom(t: keyof EntityMap, values: any[]): boolean[] {
        const enumValues = EntityStatusMap.map[t] || [];
        const filterConfig: boolean[] = [];
        enumValues.forEach(v => {
            values.includes(v) ? filterConfig.push(true) : filterConfig.push(false);
        });
        return filterConfig;
    }

    public static getFilterConfigAllIncluded(t: keyof EntityMap): boolean[] {
        const enumValues = EntityStatusMap.map[t] || [];
        const filterConfig: boolean[] = [];
        enumValues.forEach(v => {
            filterConfig.push(true);
        });
        return filterConfig;
    }

}

export interface EntityMap {
    ppemp_status_: (number | string)[];
    ppcli_status_: (number | string)[];
    ppmis_status_: number[];
    encaissement_status_: number[];
    facture_status_: string[];
    ppcde_status_: number[];
    ppcdf_status_: number[];
    ppmis_duration_status_: number[];
    ppmis_salmode_: (number | string)[];
    ppmis_facmode_: (number | string)[];
    saemp_: string[];
    saparded_: string[];
    gecli_: string[];
    gefacliview_: string[];
    print_request_type: string[];
}
