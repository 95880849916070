<div [ngClass]="className"
     class="iw-checkbox-group">
    <p-checkbox (onChange)="valueSelected.emit()"
                [(ngModel)]="value"
                [binary]="true"
                [disabled]="isDisabled"
                [falseValue]="falseValue"
                [trueValue]="trueValue"></p-checkbox>
    <div *ngIf="label"
         [ngClass]="className"
         class="iw-checkbox-group-label"
         for="item"
         title="{{label | translate}}">{{ label | translate }} <span *ngIf="required"
                                                                     class="required-indicator">*</span>
    </div>
</div>
