import {CdkOverlayOrigin, Overlay, OverlayConfig, OverlayPositionBuilder, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {
    AfterViewInit,
    Component,
    ComponentRef,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {getDeepCopy} from '@app/sam-base/helpers/objects-parser';
import {GridType} from '@app/sam-base/models/grid-type.model';
import {TranslateService} from '@ngx-translate/core';
import {isArray, sortBy} from 'lodash';
import {BlockableUI} from 'primeng/api';
import {Table} from 'primeng/table';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';

import {BaseFormControl} from '../../base/base-form-control';
import {ContextMenuEvent} from '../../core/context-menu/models';
import {DomEventService} from '../../core/dom';
import {GridFilterEvents, IwEventHubService, IwEvents} from '../../core/events';
import {arrayToObject, mergeColumnsObject} from '../../core/grid-profile/helpers';
import {GridProfile} from '../../core/grid-profile/models';
import {HotkeyService} from '../../core/hotkey/hotkey.service';
import {Hotkey} from '../../core/hotkey/models/hotkey.model';
import {KeyboardKey} from '../../core/hotkey/models/key-map.model';
import {KeyModifier} from '../../core/hotkey/models/key-modifier.model';
import {ToastService} from '../../core/toast';
import {UuidService} from '../../core/uuid/uuid.service';
// eslint-disable-next-line id-blacklist
import {number} from '../../helpers/numbers';
import {
    IwGridColumn,
    IwGridOptions,
    RowClickEvent,
    SortDirection,
    TableConfigEvent,
    TableSelectionMode,
    TableSortEvent
} from '../../models';
import {IwGridColumnConfigComponent} from '../iw-grid-column-config/iw-grid-column-config.component';
import {FilterEvent} from './components/iw-table-header/iw-table-header.component';
import {IwTableConfigComponent} from './iw-table-config/iw-table-config.component';

/** Class of scroll elent in grid */
const SCROLL_ELEMENT = 'p-datatable-wrapper';

@Component({
    selector: 'iw-table',
    templateUrl: './iw-table.component.html'
})
export class IwTableComponent<T> extends BaseFormControl<T[]> implements OnInit, OnDestroy, AfterViewInit, BlockableUI {

    /** Show loading animation */
    @Input() public isLoading = false;
    /** Enables filter column */
    @Input() public isFilterEnable = true;
    /** Enables sort icon on column */
    @Input() public isSortIconEnable = true;
    /** Enables column config menu on column */
    @Input() public isGridColumnMenuEnable = true;
    /** Enables raw table mode - hide headers and cell lines */
    @Input() public rawTableMode = false;
    /** Set first row selected by default */
    @Input() public defaultSelected?: boolean;
    /** Forces a row to be selected */
    @Input() public forceSelected = false;
    /** Id of the selected row element */
    @Input() public selectedRow?: { name: keyof T; value: any };
    /** Defines the width on the table body */
    @Input() public innerWidth: 'auto' | string = 'auto';
    /** Custom class to add to the table */
    @Input() public customClass: string = '';
    /** Enables row expand with details */
    @Input() public showGridDetails = true;
    @Input() public showFilterGrouping = true;
    @Input() public virtualScroll = false;
    @Input() public virtualScrollDelay = 250;
    @Input() public virtualScrollItemSize = 22;
    @Input() public showCaption = false;
    @Input() public selectAllFromServerEnabled = false;
    // ################### OUTPUTS #############################
    /** On a column is sorted */
    @Output() public columnSort = new EventEmitter<TableSortEvent<T>>();
    /** When selection change */
    @Output() public selectedChange = new EventEmitter<T[]>();
    /** On double click */
    @Output() public rowDoubleClick = new EventEmitter<RowClickEvent<T>>();
    @Output() public iconClick = new EventEmitter<string>();
    @Output() public iconMove = new EventEmitter<void>();
    /** On right button click */
    @Output() public contextMenuClick = new EventEmitter<ContextMenuEvent<T>>();
    /** When a filter is added or removed */
    @Output() public applyFilter = new EventEmitter<IwGridColumn<T>>();
    /** When data is changed, send it to parent */
    @Output() public dataChanged = new EventEmitter<T[]>();
    /** When a row is clicked **/
    @Output() public rowClicked = new EventEmitter<T>();
    @Output() public selectAllFromServer = new EventEmitter<{ selected: boolean, totalHits: number }>();
    @ViewChild('ptable', {static: true}) public ptable?: Table;
    /** Set if should group by */
    public showGroups = false;
    /** Table header height */
    public headerHeight = 25;
    /** Scroll area height */
    /** @deprecated
     *  Scroll height is now flex
     */
    @Input() public scrollHeight = '225px';
    public isOpen = false;
    // #############################################################
    public rowClickedIndex?: number;
    @ViewChild(CdkOverlayOrigin) private overlayOrigin?: CdkOverlayOrigin;
    @ViewChild('tableHeader') private overlayGridOrigin?: ElementRef;
    // ################### Internal #############################
    /** Sets the loading animation */
    private _internalLoading = false;
    /** Columns that are visible */
    private _avaibleColumns: IwGridColumn<T>[] = [];
    /** Refresh timeout id, to prevent multiple refresh */
    private _refreshId?: any;
    /** Rerender timeout id, to prevent multiple rerender */
    private _renderId?: any;
    /** Last column to have focus */
    private _focusColumn?: IwGridColumn<T>;
    private _lastConfig?: GridProfile<T>;
    private _lazyLoadDebouncer = new Subject<void>();
    private subscriptions = new Subject();

    constructor(protected readonly _toastService: ToastService, protected readonly _el: ElementRef,
                protected readonly _uuidService: UuidService, protected readonly _domEvent: DomEventService,
                private el: ElementRef,
                private _translateService: TranslateService,
                protected readonly _hotkeys: HotkeyService, protected readonly _events: IwEventHubService<IwEvents>,
                private overlay: Overlay, private overlayPositionBuilder: OverlayPositionBuilder) {
        super();
        this.value = [];
        this.generateElementId();
        this.lazyLoadDataSubscribe();
    }

    private _allServerEntities: T[] = [];

    public get allServerEntities() {
        return this._allServerEntities;
    }

    @Input()
    public set allServerEntities(value: T[]) {
        this._allServerEntities = value;
        if (this.areAllServerEntitiesSelected) {
            this.selectedChange.emit(value);
        }
    }

    public _areAllServerEntitiesSelected = false;

    public get areAllServerEntitiesSelected() {
        return this._areAllServerEntitiesSelected
    }

    public set areAllServerEntitiesSelected(isSelected: boolean) {
        this._areAllServerEntitiesSelected = isSelected;
        this.selectAllFromServer.emit({
            selected: isSelected,
            totalHits: this.options.totalHits!
        });
    }

    /** Padding to fix the hrizontal scroll */
    public get scrollPadding() {
        return this.innerWidth === 'auto' ? 0 : '20px';
    }

    public get selectAllButtonLabel(): string {
        return this._translateService.instant('iw_table_select_all_from_server', {totalHits: this.options.totalHits});
    }

    public get selectionMode() {
        return this._options.selectionMode || 'single';
    }

    /** Row selection mode, support 'single', 'multiple' or 'checkbox' */
    @Input()
    public set selectionMode(v: TableSelectionMode) {
        this._options.selectionMode = v;
        this.reRenderTable();
    }

    public get data() {
        return this._options.data || [];
    }

    /** Initial data to show on table */
    @Input()
    public set data(v: T[]) {
        this.setData(v);
    }

    public get columns() {
        return this._options.columns;
    }

    /** Table columns */
    @Input()
    public set columns(v: IwGridColumn<T>[]) {
        this.setColumns(v);
    }

    // ################### INPUTS #############################

    public get rowEnabled() {
        return this._options.rowEnabled;
    }

    /** Property or method that defines if row is enabled */
    @Input()
    public set rowEnabled(val: keyof T | ((row: T) => boolean) | undefined) {
        this.setRowEnabled(val);
    }

    public get columnResize() {
        const val = this._options.columnResize;
        return typeof val === 'boolean' ? val : false;
    }

    /** Defines if column is resisable */
    @Input()
    public set columnResize(val: boolean) {
        this._options.columnResize = val;
    }

    public get rowHeight(): number {
        const val = this._options.rowHeight;
        return typeof val === 'number' ? val : 25;
    }

    /** Defines row height */
    @Input()
    public set rowHeight(v: number) {
        this._options.rowHeight = v;
    }

    public get pageSize(): number {
        const v = this._options.pageSize;
        return v || 100;
    }

    /** Number of items to be loaded at a time */
    @Input()
    public set pageSize(v: number) {
        this._options.pageSize = v;
    }

    /** Number of total hits(items) existing for the query */
    @Input()
    public set totalHits(v: number) {
        this._options.totalHits = v;
    }

    public get allowHiddenColumns() {
        return this._options.allowHiddenColumns || false;
    }

    /** Set if user can show/hide columns */
    @Input()
    public set allowHiddenColumns(v: boolean) {
        this._options.allowHiddenColumns = v;
    }

    public get selected(): T[] {
        return this._value || [];
    }

    @Input()
    public set selected(v: T[]) {
        this._value = v;
        this.selectedChange.emit(v);
    }

    /** True, if selection mode is checkbox */
    public get isSelectionCheckbox() {
        return this.selectionMode === 'checkbox';
    }

    /** True, if all items are selected */
    public get areAllVisibleEntitiesSelected() {
        return this.visibleData.length > 0 && this.selectedIndex.length === this.visibleData.length;
    }

    /** Check if should show loading animation */
    public get tableIsLoading() {
        return this._internalLoading || this.isLoading;
    }

    /** Columns to show */
    public get visibleColumns() {
        return this._avaibleColumns;
    }

    /** Returns if Lazy loading is active */
    public get hasLazyLoad() {
        return !!this._options.lazyLoad;
    }

    /** Last column to have focus */
    public get lastFocusColumn() {
        return this._focusColumn;
    }

    /** Set if user can hide columns */
    public get showConfigMenu() {
        return this._options.allowHiddenColumns;
    }

    public _gridType: GridType = GridType.default;

    public get gridType() {
        return this._gridType;
    }

    /** Defines the type of the grid
     * input 'select' if you need the entity select special behaviour
     */
    @Input()
    public set gridType(type: GridType) {
        this._gridType = type ?? GridType.default;
    }

    /** Property to sort by */
    public _sortProp?: string;

    public set sortProp(prop: string) {
        this._sortProp = prop;
        if (!this.ptable || !prop) {
            return;
        }
        this.ptable._sortField = prop;
    }

    /** Table initial options */
    private _options: IwGridOptions<T> = {columns: []};

    public get options() {
        return this._options;
    }

    /** Table options */
    @Input()
    public set options(v: IwGridOptions<T>) {
        this.setOptions(v);
    }

    /** Property to group by */
    private _groupBy: Extract<keyof T, string | number> = <any>'$pk';

    public get groupBy() {
        return this._groupBy;
    }

    public set groupBy(v: Extract<keyof T, string | number>) {
        this.setGroupBy(v);
    }

    /** Property to use as data key (usualy the Id prop) */
    private _dataKey: Extract<keyof T, string | number> = <any>'id';

    public get dataKey() {
        return this.showGroups ? this.groupBy : this._dataKey;
    }

    @Input()
    public set dataKey(v: Extract<keyof T, string | number>) {
        this._dataKey = v;
        this._initialColumns = getDeepCopy(this.columns);
    }

    /** Data avaible to user */
    private _visibleData: T[] = [];

    /** Data avaible to show */
    public get visibleData() {
        return this._visibleData;
    }

    /** Sort direction */
    private _sortDir: SortDirection = 'asc';

    public set sortDir(dir: SortDirection) {
        this._sortDir = dir;
    }

    /** List of selected index */
    private _selectedIndex: number[] = [];

    /** Array of selected index */
    public get selectedIndex() {
        return this._selectedIndex;
    }

    /** Disable lazy loading on scroll */
    private _isLoadOnScrollDisabled = false;

    @Input()
    public set isLoadOnScrollDisabled(v: boolean) {
        this._isLoadOnScrollDisabled = v;
    }

    /** Columns that are initially shown */
    private _initialColumns: IwGridColumn<T>[] = [];

    public get initialColumns() {
        return this._initialColumns;
    }

    // #############################################################
    // ##################### CONSTRUCTOR ###########################

    getBlockableElement(): HTMLElement {
        return this.el.nativeElement.children[0];
    }

    // #############################################################
    // #############################################################

    public ngOnInit() {
        this._initialColumns = getDeepCopy(this.columns);
    }

    public ngOnDestroy(): void {
        this._lazyLoadDebouncer.next(undefined);
        this._lazyLoadDebouncer.complete();
        this.subscriptions.next(undefined);
        this.subscriptions.complete();
    }

    public ngAfterViewInit() {
        this.registerEventListeners();
    }

    /** Triger table render */
    public renderTable() {
        if (this._renderId) {
            clearTimeout(this._renderId);
        }
        this._renderId = setTimeout(() => {
            this.reRenderTable();
        }, 300);
    }

    /** Reload table data and settings */
    public async refresh() {
        await this.setLoadingStatus(true);
        if (this._refreshId) {
            clearTimeout(this._refreshId);
        }
        this._refreshId = setTimeout(() => {
            this.internalRefresh();
        }, 300); // Wait 300ms before requesting data
    }

    public async reset() {
        await this.setLoadingStatus(true);
        this.showGroups = false;
        this._options.data = [];
        this._visibleData = [];
        this._lastConfig = undefined;
        await this.reRenderTable();
        await this.setLoadingStatus(false);
    }

    public setLoadingStatus(v: boolean) {
        return new Promise<void>(res => {
            if (this._internalLoading === v) {
                res();
                return;
            }
            setTimeout(() => {
                this._internalLoading = v;
                res();
            }, 0);
        });
    }

    public loadGridProfile(): GridProfile<T> {
        const sort = this._sortProp ? <any>this._sortProp : this._dataKey;
        return GridProfile.fromRawData({
            cfgid: '',
            entity: '',
            name: '',
            search: '',
            userid: '',
            defaultProfile: this._lastConfig?.defaultProfile ?? false,
            columns: {
                available: arrayToObject(this.visibleColumns),
                groupBy: this.showGroups ? this._groupBy : undefined,
                sordDir: this._sortDir,
                sortBy: sort
            }
        });
    }

    public async setGridProfile(p?: GridProfile<T>) {
        if (p) {
            this.updateGridProfile(p);
        }
        this._lastConfig = p;
    }

    /** Navigate to a item based on current selected position */
    public selectNavigate(count: number = 1) {
        if (!this.visibleData) {
            return;
        }
        if (this.selectionMode === 'single') {
            let sel = number(this._selectedIndex[0], -1);
            sel += count;
            this.selectIndex(sel);
        }
    }

    public clearSelection() {
        this._selectedIndex = [];
        this.selected = [];
    }

    /** Select an index on the table */
    public selectIndex(index: number) {
        if (!this.visibleData) {
            return;
        }
        if (this.selectionMode === 'single') {
            if (index >= this.visibleData.length) {
                index = this.visibleData.length - 1;
            }
            if (index < 0) {
                index = 0;
            }
            this._selectedIndex = [index];
            this.selected = this.visibleData
                .filter((_, i) => this.selectedIndex.indexOf(i) !== -1);
            const selector = `.${SCROLL_ELEMENT} .row-${index}`;
            this._domEvent.elemRef.scrollIntoView(this._el, selector);
        }
    }

    public selectFirst() {
        this.selectIndex(0);
    }

    public selectByIndex(index: number) {
        this.selectIndex(index);
    }

    public getSelectedRowIndex() {
        if (!this.selectedRow) {
            return;
        }
        return this.visibleData.findIndex((data: any) => data[this.selectedRow?.name] === this.selectedRow?.value);
    }

    public selectLast() {
        this.selectIndex(this.visibleData.length);
    }

    public getFocus(wait?: number) {
        setTimeout(() => {
            if (this._el) {
                const index = this._selectedIndex[0] || 0;
                const selector = `.${SCROLL_ELEMENT} .row-${index}`;
                this._domEvent.elemRef.focus(this._el, selector);
            }
        }, wait || 0);
    }

    // ##################### table tools ##############################

    public countRows(prop: keyof T, value: any) {
        return this._visibleData.filter(c => c[prop] === value).length;
    }

    public toggleSelect(index: number) {
        this.areAllServerEntitiesSelected = false;
        if (this.selectionMode === 'single') {
            this._selectedIndex = this._selectedIndex[0] === index && !this.isSelectionForced() ? [] : [index];
        } else {
            const onIndex = this._selectedIndex.indexOf(index);
            if (onIndex === -1) {
                this._selectedIndex.push(index);
            } else {
                this._selectedIndex.splice(onIndex, 1);
            }
        }
        this.selected = this.visibleData
            .filter((_, i) => this.selectedIndex.indexOf(i) !== -1);
    }

    public deselectAll() {
        this._selectedIndex = [];
        this.selected = [];
        this.selectedChange.emit([]);
    }

    public isSelectionForced(): boolean {
        return (this.defaultSelected ?? false) && this.forceSelected;
    }

    public toggleAll() {
        if (this.selectionMode === 'single') {
            return;
        }
        if (this.areAllVisibleEntitiesSelected) {
            this._selectedIndex = [];
        } else {
            this._selectedIndex = [];
            // eslint-disable-next-line guard-for-in
            for (const i in this.visibleData) {
                this.selectedIndex.push(Number(i));
            }
        }
        this.selectedChange.emit(this.getSelected());
    }

    public getSelected() {
        if (this.areAllServerEntitiesSelected) {
            return this.allServerEntities;
        }
        return this.visibleData
            .filter((_, i) => this._selectedIndex.indexOf(i) !== -1);
    }

    // ################### events actions #############################

    public async onSelectedColumnChange() {
        this.renderTable();
    }

    public async onColumnFilterClick(c: IwGridColumn<T>) {
        const refColumn = this.columns.find(e => e.prop === c.prop);
        if (!refColumn) {
            return;
        }
        this._focusColumn = refColumn;
    }

    /** When scroll reach end, load mor data */
    public async onScrollEnd(event: any) {
        await this.lazyLoadData();
        await this.setLoadingStatus(false);
    }

    public async onConfigClick() {
        if (!this.overlayOrigin) {
            return;
        }

        // Overlay configuration
        const config = new OverlayConfig({
            hasBackdrop: true,
            panelClass: 'p-overlaypanel',
            positionStrategy: this.overlayPositionBuilder
                .flexibleConnectedTo(this.overlayOrigin.elementRef)
                .withPositions([
                    {
                        originX: 'center',
                        originY: 'bottom',
                        overlayX: 'center',
                        overlayY: 'top',
                        offsetY: 8
                    }])
        });

        // Overlay creation
        const overlayRef = this.overlay.create(config);
        const configPortal = new ComponentPortal(IwTableConfigComponent);
        const configRef: ComponentRef<IwTableConfigComponent<any>> = overlayRef.attach(configPortal);

        this.configOverlayInitialization(configRef, overlayRef);
    }

    public onFilter({
                        column,
                        event,
                        element
                    }: { column: IwGridColumn<T>; event: FilterEvent<T>; element: ElementRef }) {

        this._focusColumn = this._options.columns.find(c => c.prop === column.prop);

        // Overlay configuration
        const config = new OverlayConfig({
            hasBackdrop: true,
            panelClass: 'p-overlaypanel',
            positionStrategy: this.overlayPositionBuilder
                .flexibleConnectedTo(element)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                        offsetY: 8
                    }])
        });

        // Overlay creation
        const overlayRef = this.overlay.create(config);
        const configPortal = new ComponentPortal(IwGridColumnConfigComponent);
        const configRef: ComponentRef<IwGridColumnConfigComponent<any>> = overlayRef.attach(configPortal);

        this.filtersOverlayInitialization(configRef, overlayRef);
    }

    public onConfigReset(event: TableConfigEvent<T>) {
        this.showGroups = false;
        this._groupBy = <any>'$pk';
        this._sortProp = undefined;
        this.onConfigChange(event);
    }

    public onConfigChange(event: TableConfigEvent<T>) {
        this.setColumns(event.columns);
    }

    public async onSort(sortEvent: { field: keyof T; order: number }) {
        const column = this._avaibleColumns.find(c => c.prop === sortEvent.field);
        const direction: SortDirection = sortEvent.order < 0 ? 'desc' : 'asc';
        if (!column) {
            return;
        }

        this._sortDir = direction;
        this._sortProp = <any>sortEvent.field;

        if (this.showGroups === true && this._groupBy !== sortEvent.field) {
            this.groupBy = <any>undefined;
        }

        const event: TableSortEvent<T> = {
            column,
            newValue: direction,
            sorts: [
                {
                    dir: direction,
                    prop: <any>column.prop
                }]
        };

        this.deselectAll();
        this.columnSort.emit(event);
        this.lazyLoadReload();
    }

    public onRowDoubleClick(e: RowClickEvent<T>) {
        this.rowDoubleClick.emit(e);
    }

    public onRowClick(e: RowClickEvent<T>) {
        if (this.selectionMode === 'single') {
            this.toggleSelect(e.index);
        }
        if (e.index === this.rowClickedIndex) {
            this.rowClickedIndex = undefined;
        } else {
            this.rowClickedIndex = e.index;
        }
        this.rowClicked.emit(e.row);
    }

    public onCheckboxClick(e: RowClickEvent<T>) {
        if (this.selectionMode === 'checkbox') {
            this.toggleSelect(e.index);
        }
    }

    public onHeaderCheckboxClick() {
        this.toggleAll();
    }

    public onIconClick(prop: string) {
        this.iconClick.emit(prop);
    }

    public onIconMove() {
        this.iconMove.emit();
    }

    public onContextMenu(e: RowClickEvent<T>) {
        e.base.preventDefault();

        let clickedItemIsOneOfTheSelected = this._selectedIndex.indexOf(e.index) !== -1;
        if (!clickedItemIsOneOfTheSelected) {
            this.clearSelection()
        }

        const selected = this.getSelected();
        if (!selected.length) {
            selected.push(e.row);
        }

        const menuEvent: ContextMenuEvent<T> = {
            column: e.column,
            event: e.base,
            value: e.row,
            selected
        };
        this.contextMenuClick.emit(menuEvent);
    }

    public onApplyFilters(filters: IwGridColumn<T>) {
        this.refresh();
        this.applyFilter.emit(filters);
    }

    /** Register DOM event listeners */
    public registerEventListeners() {
        this.addScrollListener();
        this._domEvent.window.resize
            .subscribe(() => this.reRenderTable());
        this.addHotkeys();
        this.subscribeIwEvents();
    }

    public softRefresh() {
        this.lazyLoadReload();
    }

    public onSelectAllButton() {
        this.areAllServerEntitiesSelected = true;
    }

    public clearSelectSelection() {
        this.areAllServerEntitiesSelected = false;
        this.deselectAll();
    }

    // ################### set actions #############################
    protected setOptions(v: IwGridOptions<T>) {
        this._options = {
            ...this._options, ...v
        };
        this._visibleData = v.data || [];
        this.refresh();
    }

    protected setData(v: T[]) {
        this.options.data = v;
        this._visibleData = v;
        this.refresh();
    }

    protected setColumns(v: IwGridColumn<T>[]) {
        this._options.columns = v;
        this.refresh();
    }

    protected setRowEnabled(v: keyof T | ((row: T) => boolean) | undefined) {
        this._options.rowEnabled = v;
        this.refresh();
    }

    protected setGroupBy(v: Extract<keyof T, string | number>) {
        const valid = !!(v && v.toString());
        this._groupBy = v;
        this.showGroups = valid;

        this._sortDir = 'asc';
        this._sortProp = <any>v;
        this.lazyLoadReload();

        if (this.ptable && this._sortProp) {
            this.ptable.sortField = this._sortProp;
        }

        setTimeout(() => {
            this._visibleData = [...this._visibleData];
            this.reRenderTable();
        }, 0);
    }

    // ################## internal actions ############################
    /** Load more data */
    protected async lazyLoadData() {
        try {
            if (this._options.lazyLoadScroll) {
                await this.setLoadingStatus(true);
                const data = await this._options.lazyLoadScroll();

                /** Filter out from data if already in the grid */
                const dataToAdd = this.filterOutDuplicate(data);

                if (dataToAdd && dataToAdd.length) {
                    this._visibleData.push(...dataToAdd);
                    this.dataChanged.emit(this._visibleData);
                }
                await this.setLoadingStatus(false);
            }
        } catch {
        }
    }

    /** Reload current data */
    protected async lazyLoadReload() {
        try {
            if (this._options.lazyLoadReload) {
                await this.setLoadingStatus(true);
                const data = await this._options.lazyLoadReload();
                if (data && isArray(data)) {
                    this._visibleData = data;
                }
                await this.setLoadingStatus(false);
            } else {
                this._visibleData = [...this._visibleData];
            }
            this.dataChanged.emit(this._visibleData);
        } catch {
            this._toastService.error('error_load_data_table');
        }
    }

    /** Reload list of visible columns */
    protected reloadAvaibleColumns() {
        return new Promise<void>(res => {
            setTimeout(() => {
                const grabIndex = (n?: number) => n === undefined ? -1 : n;
                const indexVals = this.columns.map(e => grabIndex(e.index));
                // Calculate the last index
                let count = Math.max(...indexVals);
                const columns = this.columns
                    .filter(c => !c.hidden) // Remove hidden
                    .map(e => ({
                        ...e,
                        index: grabIndex(e.index) < 0 ? (++count) : e.index // Calculate index
                    }));
                this._avaibleColumns = sortBy(columns, c => c.index); // Sort by index
                res();
            }, 0);
        });
    }

    /** Calc scroll height */
    protected async calculateScrollHeight() {
        return new Promise<void>(res => {
            setTimeout(() => {
                const fullHeight = this.calcTableHeight();
                const headerHeight = this.calcHeaderHeight();
                this.scrollHeight = (fullHeight - headerHeight) + 'px';
                res();
            }, 0);
        });
    }

    protected async reRenderTable() {
        await this.reloadAvaibleColumns();
        await this.calculateScrollHeight();
    }

    // eslint-disable-next-line complexity
    protected async internalRefresh() {
        await this.setLoadingStatus(true);
        await this.reRenderTable();
        await this.lazyLoadReload();
        this._selectedIndex = [];
        await this.setLoadingStatus(false);
        if (this.defaultSelected && !this.selectedRow) {
            this.selectFirst();
        } else if (this.defaultSelected && this.selectedRow) {
            const rowIndex = this.getSelectedRowIndex();
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            rowIndex ? this.selectByIndex(rowIndex) : this.selectFirst();
        }
    }

    protected clearColumnFilter(k: keyof T) {
        const refColumn = this.columns.find(e => e.prop === k);
        if (refColumn) {
            refColumn.filterOperator = undefined;
            refColumn.filterQuery = undefined;
            refColumn.filterQuery2 = undefined;
            this.reset();
        }
    }

    private filterOutDuplicate(data: T[]) {
        const pk = this._dataKey;
        const visibleDataPksList = this._visibleData.map(e => e[pk]);
        const filteredData = data.filter(e => !(visibleDataPksList.includes(e[pk])));

        return filteredData;
    }

    private configOverlayInitialization(configRef: ComponentRef<IwTableConfigComponent<any>>, overlayRef: OverlayRef) {
        // Overlay inputs injection
        configRef.instance.visibleColumns = this.visibleColumns;
        configRef.instance.initialColumns = this.initialColumns;
        const subscriptions = new Subject();

        // Overlay subscriptions listeners
        configRef.instance.columnsChange
            .pipe(takeUntil(subscriptions))
            .subscribe((ev: TableConfigEvent<T>) => {
                this.onConfigChange(ev);
                this.clearSubs(overlayRef, subscriptions);
            });
        configRef.instance.resetConfig
            .pipe(takeUntil(subscriptions))
            .subscribe((ev: TableConfigEvent<T>) => {
                this.onConfigReset(ev);
                this.clearSubs(overlayRef, subscriptions);
            });
        overlayRef.backdropClick()
            .pipe(takeUntil(subscriptions))
            .subscribe(() => {
                this.clearSubs(overlayRef, subscriptions);
            });
    }

    private filtersOverlayInitialization(configRef: ComponentRef<IwGridColumnConfigComponent<any>>,
                                         overlayRef: OverlayRef) {
        // Overlay inputs injection
        configRef.instance.column = this.lastFocusColumn;
        configRef.instance.groupBy = this.groupBy;
        configRef.instance.showGrouping = this.showFilterGrouping;
        const subscriptions = new Subject();

        // Overlay subscriptions listeners
        configRef.instance.applyFilters
            .pipe(takeUntil(subscriptions))
            .subscribe((ev: IwGridColumn<T>) => {
                this.onApplyFilters(ev);
                this.clearSubs(overlayRef, subscriptions);
            });
        overlayRef.backdropClick()
            .pipe(takeUntil(subscriptions))
            .subscribe(() => {
                this.clearSubs(overlayRef, subscriptions);
            });
    }

    private clearSubs(overlayRef: OverlayRef, sub: Subject<unknown>) {
        overlayRef.detach();
        sub.next(undefined);
        sub.complete();
    }

    private async updateGridProfile(p: GridProfile<T>) {
        await this.setLoadingStatus(true);
        const columns = mergeColumnsObject(this._options.columns, p.columns.available);
        this._options.columns = columns;
        this._initialColumns = getDeepCopy(columns);
        this._sortDir = p.columns.sordDir || 'asc';
        this._sortProp = p.columns.sortBy;
        this.showGroups = false;
        if (p.columns.groupBy) {
            this.setGroupBy(p.columns.groupBy);
            await this.setLoadingStatus(false);
        } else {
            this.refresh();
        }
    }

    // ################## old school hacks  ########################
    private calcHeaderHeight(): number {
        const headerElem = this._domEvent
            .elemRef.querySelector(this._el, '.iw-table-header');
        return headerElem ? headerElem.getBoundingClientRect().height : 0;
    }

    /** Calculate the height of the container */
    private calcTableHeight(): number {
        if (this._el.nativeElement) {
            return this._el.nativeElement
                .getBoundingClientRect().height;
        }

        return 600;
    }

    /** Apply a random value as element id */
    private generateElementId() {
        if (this._el.nativeElement && !this._el.nativeElement.id) {
            const id = this._uuidService.generateDomId();
            this._el.nativeElement.id = id;
        }
    }

    /** Add listener to table scroll */
    private addScrollListener() {
        const elemId = this._el.nativeElement && this._el.nativeElement.id;
        const SCROLL_EVENT = 'scroll';
        const scrollElemSelector = `#${elemId} .${SCROLL_ELEMENT}`;
        const element = document.querySelector(scrollElemSelector);
        if (!element) {
            return;
        }
        element.addEventListener(SCROLL_EVENT, e => {
            if (e.target) {

                if (this._isLoadOnScrollDisabled) {
                    return;
                }
                // Since we grab the element with a
                // Selector is ok to guess it is an HTML element
                const elem = <HTMLElement>e.target;
                const scrollVariation = elem.scrollHeight - elem.scrollTop;
                if (scrollVariation <= (elem.clientHeight + 10)) {
                    // When scroll is at bottom
                    this._lazyLoadDebouncer.next();
                }
            }
        });
    }

    private lazyLoadDataSubscribe() {
        this._lazyLoadDebouncer
            .pipe(debounceTime(200))
            .subscribe(() => this.lazyLoadData());
    }

    private subscribeIwEvents() {
        this._events.forType<keyof T>(GridFilterEvents.clearColumnFilter)
            .pipe(takeUntil(this.subscriptions))
            .subscribe(({payload}) => payload ? this.clearColumnFilter(payload) : undefined);
    }

    private addHotkeys() {
        this.addHotkeyNavPrev();
        this.addHotkeyNavNext();
        this.addHotkeyNavigate();
    }

    private addHotkeyNavNext() {
        const hotkey = new Hotkey(KeyModifier.alt, KeyboardKey.ArrowDown, 'TABLE_SELECT_NEXT', () => this.selectNavigate(1));
        this._hotkeys.registerHotkey(hotkey, this._el);
    }

    private addHotkeyNavPrev() {
        const hotkey = new Hotkey(KeyModifier.alt, KeyboardKey.ArrowUp, 'TABLE_SELECT_PREV', () => this.selectNavigate(-1));
        this._hotkeys.registerHotkey(hotkey, this._el);
    }

    private addHotkeyNavigate() {
        const hotkey = new Hotkey(KeyModifier.none, KeyboardKey.Enter, 'TABLE_GOTO_FORM', () => {
            const sel = number(this._selectedIndex[0], -1);
            if (sel >= 0) {
                const row = this.visibleData[sel];
                const event: RowClickEvent<T> = {
                    column: this._avaibleColumns[0],
                    row,
                    index: 0,
                    base: new MouseEvent('hotkey-navigation')
                };
                this.rowDoubleClick.emit(event);
            }
        });
        this._hotkeys.registerHotkey(hotkey, this._el);
    }
}
