import {Component, OnInit} from '@angular/core';
import {AgencyHandlerService} from '@app/sam-base/core/auth/services/agency-handler.service';
import {IwGridColumn, RowClickEvent} from '@app/sam-base/models';
import {TT6Ges} from '@app/sam-base/models/common/tt6Ges';

/**
 * Component created regarding SAM-4555
 */
@Component({
    templateUrl: 'gestion.component.html'
})
export class GestionComponent implements OnInit {

    public agencies: TT6Ges[] = [];
    public columns = this.getColumns();
    public display = true;
    public selected?: TT6Ges;

    constructor(private readonly _agency: AgencyHandlerService) {
    }

    public ngOnInit() {
        this._agency.getGestions()
            .subscribe(gestions => this.agencies = gestions);
    }

    /**
     * Set selected row to selected var
     *
     * @param event TT6Ges selected
     */
    public setSelectedRow(event: TT6Ges[]) {
        this.selected = (event && event[0]) || undefined;
    }

    /**
     * Open gestion selected
     */
    public openGestionEvent(event: RowClickEvent<TT6Ges>) {
        this.selected = (event.row) || undefined;
        this.openGestion();
    }

    /**
     * Open gestion selected
     */
    public openGestion() {
        if (this.selected && this.selected.nogestion) {
            this._agency.setAgencyId(this.selected.nogestion);
        }
    }

    /**
     * Close dialog
     */
    public onPressCancel() {
        this.display = false;
    }

    /**
     * Columns to show in table of gestions
     */
    private getColumns(): IwGridColumn<TT6Ges>[] {
        return [
            {
                prop: 'nogestion',
                name: 'number',
                width: 40,
                index: 0
            },
            {
                prop: 'libgestion',
                name: 'libelle',
                index: 1
            }];
    }
}
